import Button from "./Button";
import bannerImgModal1 from "../../../images/svg/bannerImgModal1.svg";
import bannerImgModal2 from "../../../images/svg/bannerImgModal2.svg";
import bannerImgModal3 from "../../../images/svg/bannerImgModal3.svg";
import bannerImgModal4 from "../../../images/svg/bannerImgModal4.svg";
import Input from "./Input";
import Textarea from "./Textarea";
import { useEffect } from "react";
import api from "../../../services/api";
import { useState } from "react";
import InputRadius from "./InputRadius";

const ModalPartnerDetails = ({
  image,
  company,
  description,
  discount,
  modalConfirm,
  closeModal,
  form,
  id,
}) => {
  const [dataForm, setDataForm] = useState({});
  const [details, setDetails] = useState("");

  const onSubmit = async () => {
    JSON.stringify(dataForm);

    const data = {
      interest: JSON.stringify(dataForm),
      partner_id: id,
    };

    try {
      const token = localStorage.getItem("@QuboClient:");

      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      await api.post("/partner/interest", data, config);

      modalConfirm();
    } catch (error) {
      console.log(error.response.data.message);
    }
    //modalConfirm()
  };

  const getSolution = () => {
    const solution = localStorage.getItem("@PARTNER_SOLUTIONS:");
    setDetails(solution);
    setTimeout(() => {
      localStorage.removeItem("@PARTNER_SOLUTIONS:");
    }, 300);
  };

  useEffect(() => {
    if (form) {
      const obj = {};
      form.forEach((item) => {
        obj[item.name] = "";
      });
      setDataForm(obj);
    }
  }, [form]);

  useEffect(() => {
    setDataForm({ ...dataForm, details: details });
  }, [details]);

  useEffect(() => {
    getSolution();
  }, []);

  return (
    <div
      style={{
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        height: "589px",
        width: "754px",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "22px",
          borderBottom: "1px solid #D7D7D7",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "21px",
          }}
        >
          <div onClick={closeModal} style={{ cursor: "pointer" }}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.48406 6.36766L12.4871 1.35916C12.7976 1.04823 12.7976 0.544109 12.4871 0.2332C12.1765 -0.0777335 11.673 -0.0777335 11.3624 0.2332L6.35997 5.24222L1.35752 0.2332C1.04696 -0.0777335 0.543453 -0.0777335 0.232919 0.2332C-0.0776148 0.544134 -0.0776396 1.04825 0.232919 1.35916L5.23589 6.36766L0.232919 11.3762C-0.0776396 11.6871 -0.0776396 12.1912 0.232919 12.5021C0.543478 12.8131 1.04699 12.8131 1.35752 12.5021L6.35997 7.4931L11.3624 12.5021C11.673 12.8131 12.1765 12.8131 12.487 12.5021C12.7976 12.1912 12.7976 11.6871 12.487 11.3762L7.48406 6.36766Z"
                fill="black"
              />
            </svg>
          </div>
          <p className="textTitleCloseModal">Detalhes do parceiro</p>
        </div>
        <Button
          onClick={onSubmit}
          height="33px"
          background="var(--color-primary)"
          borderRadius="60px"
          marginTop="0"
          name="Enviar"
          selectBtn={"Enviar"}
          border="none"
          width="134px"
        />
      </div>

      <div
        style={{
          height: "84%",
          overflowY: "scroll",
          display: "flex",
        }}
      >
        <div
          style={{
            padding: "27px 23px",
            display: "flex",
            flexDirection: "column",
            gap: "17px",
            width: "50%",
          }}
        >
          <div>
            <img
              style={{
                width: "81px",
                height: "27px",
                objectFit: "contain",
              }}
              src={image}
            />
          </div>

          <h4 className="textCompanyTitleCardModal">{company}</h4>

          <p className="textCompanyDescriptionModal">{description}</p>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <img
              style={{ width: "155px", objectFit: "cover" }}
              src={bannerImgModal1}
            />
            <img
              style={{ width: "155px", objectFit: "cover" }}
              src={bannerImgModal2}
            />
            <img
              style={{ width: "155px", objectFit: "cover" }}
              src={bannerImgModal3}
            />
            <img
              style={{ width: "155px", objectFit: "cover" }}
              src={bannerImgModal4}
            />
          </div>
        </div>

        <div
          style={{
            width: "50%",
          }}
        >
          <div
            style={{
              padding: "27px 23px",
            }}
          >
            <h3 className="textTitleFormModal">Formulario de interesse</h3>

            <div>
              {form?.map((item, index) => {
                console.log(item.name.includes(":"));
                const onChangeValue = (keyValue, value) => {
                  const data = dataForm;
                  data[keyValue] = value;
                  setDataForm(data);
                  console.log(data);
                };

                if (item.name.includes(":")) {
                  const title = item.name.split(":")[0];
                  const content = item.name.split(":")[1].split(",");

                  return (
                    <InputRadius
                      onChange={(e) => onChangeValue(title, e)}
                      name={title}
                      options={content}
                    />
                  );
                }

                return (
                  <>
                    <Input
                      width="100%"
                      onChange={(e) => onChangeValue(item.name, e.target.value)}
                      key={item.id}
                      name={item.name}
                      placeholder={`Insira o ${String(
                        item.name
                      ).toLowerCase()}`}
                      type={"text"}
                    />
                  </>
                );
              })}
              <Textarea
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                name="Observações adicionais"
                width="100%"
                height="160px"
                placeholder="Observações adicionais"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPartnerDetails;
