import { useEffect, useState } from "react";
import uploadUserIcon from "../../../images/svg/uploadUserIcon.svg";
import Input from "./Input";
import Button from "./Button";
import api from "../../../services/api";
import app from "../../../firebase/database";
import { toast } from "react-toastify";
import Loading from "./Loading";
import InputSelect from "./InputSelect";

const ProfileCard = () => {
  const [typeProfile, setTypeProfile] = useState("");
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [imageFirebase, setImageFirebase] = useState();

  const [pictureFirebase, setPictureFirebase] = useState();
  const [updateState, setUpdateState] = useState(false);
  const [picture, setPicture] = useState("");

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");

  const [representative, setRepresentative] = useState("");
  const [nationality, setNationality] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");

  const [profession, setProfession] = useState("");
  const [cpf, setCPF] = useState("");
  const [rg, setRG] = useState("");

  const [address, setAddress] = useState("");
  const [acting, setActing] = useState("");

  const [password, setPassword] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [email, setEmail] = useState("");

  const updateProfile = async () => {
    try {
      const response = await api.get("/profile");
      const userId = response.data.id;

      let data = {
        name: name,
        company: company,
        picture: pictureFirebase,
        cnpj: cnpj,
        email: email,
        phone: phone,
        representative: representative,
        nationality: nationality,
        marital_status: maritalStatus,
        profession: profession,
        cpf: cpf,
        rg: rg,
        address: address,
        acting: acting,
      };

      if (password) {
        data.password = password;
        data.password_hash = password;
      }

      try {
        const updateUser = await api.patch(`/client/${userId}`, data);

        if (updateUser.status == 200) {
          toast.success("Dados atualizados!", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setUpdateState(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const loadImage = (file) => {
    setImage(file);
    setImageFirebase(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      setImagePreview(reader.result);
    };
  };

  const uploadImg = async () => {
    if (imageFirebase) {
      imageFirebase &&
        (await app
          .storage()
          .ref(`/users/${imageFirebase.name}`)
          .put(imageFirebase)
          .then(() => {
            app
              .storage()
              .ref(`/users/${imageFirebase.name}`)
              .getDownloadURL()
              .then(async (url) => {
                setPictureFirebase(url);
                setImage();
                setImageFirebase();
                setUpdateState("upload image profile");
              });
          }));
    } else {
      setUpdateState("upload image profile");
    }
  };

  const getProfile = async () => {
    try {
      const response = await api.get("/profile");
      setTypeProfile(response.data?.permission);

      setName(response.data?.name);
      setPicture(response.data?.picture);
      setCompany(response.data?.company);

      setPhone(response.data?.phone);
      setCnpj(response.data?.cnpj);
      setEmail(response.data?.email);

      setRepresentative(response.data?.representative);
      setNationality(response.data?.nationality);
      setMaritalStatus(response.data?.marital_status);

      setProfession(response.data?.profession);
      setCPF(response.data?.cpf);
      setRG(response.data?.rg);

      setAddress(response.data?.address);
      setActing(response.data?.acting);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (updateState == "loading") {
      uploadImg();
    }
    if (updateState == "upload image profile") {
      updateProfile();
    }
  }, [updateState]);

  useEffect(() => {
    getProfile();
  }, []);

  const boxLineInput = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  };

  return name ? (
    <>
      <div
        style={{
          width: "713px",
          background: "#FFFFFF",
          border: "1px solid #D7D7D7",
          borderRadius: "15px",
          padding: "37px",
          margin: "0 auto",
        }}
      >
        <div>
          <input
            onChange={(e) => loadImage(e.target.files[0])}
            accept="image/jpeg, image/png"
            name="file"
            id="file"
            type="file"
            style={{ display: "none" }}
          />
          {imagePreview != undefined ? (
            <label
              htmlFor="file"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "3.93rem",
              }}
            >
              <img
                src={"data:image/;base64;" + imagePreview}
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "100%",
                  border: "1px solid #d7d7d7",
                  objectFit: "cover",
                }}
              />
            </label>
          ) : picture ? (
            <label
              htmlFor="file"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "3.93rem",
              }}
            >
              <img
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "100%",
                  border: "1px solid #d7d7d7",
                  objectFit: "contain",
                }}
                src={picture}
              />
            </label>
          ) : (
            <label
              htmlFor="file"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "3.93rem",
              }}
            >
              <img src={uploadUserIcon} />
            </label>
          )}
        </div>
        {(typeProfile == "Administrativo" && (
          <>
            <div style={boxLineInput}>
              <Input
                onChange={(e) => setName(e.target.value)}
                value={name}
                name="Nome"
                width="100%"
                placeholder="Insira seu nome"
                type={"text"}
              />
              <Input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                name="E-mail"
                width="100%"
                placeholder="Insira seu email"
                type={"text"}
              />
            </div>

            <div style={boxLineInput}>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                name="Senha"
                width="308px"
                placeholder="Insira uma senha"
                type={"password"}
              />
            </div>
          </>
        )) ||
          (typeProfile == "Funcionario" && (
            <>
              <div style={boxLineInput}>
                <Input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  name="Nome"
                  width="100%"
                  placeholder="Insira seu nome"
                  type={"text"}
                />
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  name="E-mail"
                  width="100%"
                  placeholder="Insira seu email"
                  type={"text"}
                />
              </div>

              <div style={boxLineInput}>
                <Input
                  onChange={(e) => setPassword(e.target.value)}
                  name="Senha"
                  width="308px"
                  placeholder="Insira uma senha"
                  type={"password"}
                />
              </div>
            </>
          )) ||
          (typeProfile != "Funcionario" && typeProfile != "Administrativo" && (
            <>
              <div style={boxLineInput}>
                <Input
                  onChange={(e) => setCompany(e.target.value)}
                  value={company}
                  name="Nome da empresa"
                  width="100%"
                  placeholder="Insira o nome da sua empresa"
                  type={"text"}
                />
                <Input
                  onChange={(e) => setCnpj(e.target.value)}
                  value={cnpj}
                  name="CNPJ da empresa"
                  width="100%"
                  placeholder="Insira o cnpj da sua empresa"
                  type={"text"}
                />
              </div>
              <div style={boxLineInput}>
                <Input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  name="Nome"
                  width="100%"
                  placeholder="Insira seu nome"
                  type={"text"}
                />
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  name="E-mail"
                  width="100%"
                  placeholder="Insira seu email"
                  type={"text"}
                />
              </div>
              <div style={boxLineInput}>
                <Input
                  onChange={(e) => setRepresentative(e.target.value)}
                  value={representative}
                  name="Representante legal"
                  width="100%"
                  placeholder="Insira o representante legal"
                  type="text"
                />
                <Input
                  onChange={(e) => setNationality(e.target.value)}
                  value={nationality}
                  name="Nacionalidade"
                  width="100%"
                  placeholder="Insira a nacionalidade"
                  type="text"
                />
              </div>
              <div style={boxLineInput}>
                <Input
                  onChange={(e) => setMaritalStatus(e.target.value)}
                  value={maritalStatus}
                  name="Estado Civil"
                  width="100%"
                  placeholder="Insira o estado civil"
                  type="text"
                />
                <Input
                  onChange={(e) => setProfession(e.target.value)}
                  value={profession}
                  name="Profissão"
                  width="100%"
                  placeholder="Insira a profissão"
                  type="text"
                />
              </div>
              <div style={boxLineInput}>
                <Input
                  mask={"999.999.999-99"}
                  onChange={(e) => setCPF(e.target.value)}
                  value={cpf}
                  name="CPF"
                  width="100%"
                  placeholder="Insira o CPF"
                  type="text"
                />
                <Input
                  onChange={(e) => setRG(e.target.value)}
                  value={rg}
                  mask="99.999.999-9"
                  name="Documento (RG)"
                  width="100%"
                  placeholder="Insira o documento (RG)"
                  type="text"
                />
              </div>
              <div style={boxLineInput}>
                <Input
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  name="Endereço"
                  width="100%"
                  placeholder="Insira o endereço"
                  type="text"
                />
                <InputSelect
                  onChange={(e) => setActing(e.target.value)}
                  value={acting}
                  name="Setor de atuação"
                  width="100%"
                  type="text"
                />
              </div>
              <div style={boxLineInput}>
                <Input
                  mask={"99 99999-9999"}
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  name="Número de Whats App"
                  width="100%"
                  placeholder="Insira seu número de telefone"
                  type={"text"}
                />
                <Input
                  onChange={(e) => setPassword(e.target.value)}
                  name="Senha"
                  width="308px"
                  placeholder="Insira uma senha"
                  type={"password"}
                />
              </div>
            </>
          ))}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "47px",
          }}
        >
          <Button
            width="331px"
            height="43px"
            background={"var(--color-primary)"}
            borderRadius="60px"
            marginTop="0"
            selectBtn={"Salvar"}
            name="Salvar"
            color={"var(--white)"}
            border="none"
            onClick={() => setUpdateState("loading")}
          />
        </div>
      </div>
    </>
  ) : (
    <Loading />
  );
};

export default ProfileCard;
