import checkOnQuestions from "../../../images/svg/checkOnQuestions.svg";
import checkOffQuestions from "../../../images/svg/checkOffQuestions.svg";
import { DashboardClientContext } from "../../../context/DashboardClient";
import { useContext } from "react";
import { useState } from "react";

const CardContentInitialQuestion = ({
  option,
  question,
  type,
  journey,
  update,
  questId,
  openAll,
  verifyCheck,
}) => {
  const {
    journeyResponse,
    initialQuestionsResponse,
    onSubmitAddResponseInitialQuestion,
    onSubmitAddResponseJourney,
  } = useContext(DashboardClientContext);

  /* STYLES */
  const cardQuestionsContent = {
    display: "flex",
    gap: "60px",
    alignItems: "center",
    paddingTop: "33px",
    paddingBottom: "24px",
  };

  const questionsCard = {
    display: "flex",
    flexDirection: "row",
    gap: "25px",
  };

  /* LIST ONLY THE ANSWERS TO A QUESTION */
  const initialQuestionNameResponse = initialQuestionsResponse.map((item) => {
    if (item.question == question.name && item.response !== null) {
      return item.response.response;
    }
  });

  /* LIST ONLY THE ANSWERS FOR A JOURNEY */
  const journeyNameResponse = journeyResponse.map((item) => {
    if (item.question == question.name) {
      console.log(item);
      return item?.response?.response;
    }
  });

  /* ADD ANSWER TO INITIAL QUESTIONS */
  const onResponseQuestion = async (questionInitial, responseInitial) => {
    const data = {
      question: questionInitial.name,
      response: responseInitial,
    };
    await onSubmitAddResponseInitialQuestion(data);
    update();
  };

  /* ADD ANSWER TO JOURNEY */
  const onResponseJourney = async (questionInitial, responseInitial) => {
    const data = {
      journey_id: journey,
      question: questionInitial.name,
      response: responseInitial,
      quest_id: questId,
    };
    console.log(questId);
    await onSubmitAddResponseJourney(data);
    update();
  };

  /* CHANGE THE STATE OF THE ICON */
  const boleanResponseQuestion = (name, list) => {
    const exist = list.filter((item) => item == name);

    if (exist.length > 0) {
      verifyCheck(true);
      return true;
    }
    return false;
  };

  /* CHANGE THE STATE OF THE ICON */
  const boleanResponseJourney = (name, list) => {
    const exist = list.filter((item) => item === name);

    if (exist.length > 0) {
      verifyCheck(true);
      return true;
    }
    return false;
  };

  return (
    (type == "Initial Questions" && (
      <>
        <div
          style={{
            ...cardQuestionsContent,
            height: "auto",
            transition: "height 0.9s ease-in-out",
            overflow: "hidden",
            flexDirection: "column",
          }}
        >
          <div style={{ ...questionsCard, width: "100%" }}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => onResponseQuestion(question, option.name)}
              src={
                boleanResponseQuestion(option.name, initialQuestionNameResponse)
                  ? checkOnQuestions
                  : checkOffQuestions
              }
            />
            <p>{option.name}</p>
          </div>
        </div>
      </>
    )) ||
    (type == "Questions" && (
      <>
        <div
          style={{
            ...cardQuestionsContent,
            height: "auto",
            transition: "height 0.9s ease-in-out",
            overflow: "hidden",
          }}
        >
          {question.positive?.task && (
            <div style={questionsCard}>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => onResponseJourney(question, "positive")}
                src={
                  boleanResponseJourney("positive", journeyNameResponse)
                    ? checkOnQuestions
                    : checkOffQuestions
                }
              />
              <p>Sim</p>
            </div>
          )}

          {question.partial?.task && (
            <div style={questionsCard}>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => onResponseJourney(question, "partial")}
                src={
                  boleanResponseJourney("partial", journeyNameResponse)
                    ? checkOnQuestions
                    : checkOffQuestions
                }
              />
              <p>Parcial</p>
            </div>
          )}

          {question.negative?.task && (
            <div style={questionsCard}>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => onResponseJourney(question, "negative")}
                src={
                  boleanResponseJourney("negative", journeyNameResponse)
                    ? checkOnQuestions
                    : checkOffQuestions
                }
              />
              <p>Não</p>
            </div>
          )}
        </div>
      </>
    ))
  );
};

export default CardContentInitialQuestion;
