import React, { useState, useEffect } from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../components/Sidebar";
import HeaderDashboard from "../components/HeaderDashboard";
import company1 from "../../../images/svg/company1.svg";
import company2 from "../../../images/svg/company2.svg";
import company3 from "../../../images/svg/company3.svg";
import company4 from "../../../images/svg/company4.svg";
import company5 from "../../../images/svg/company5.svg";
import Button from "../components/Button";
import CardPartners from "../components/CardPartners";
import ModalContainer from "../components/ModalContainer";
import ModalPartnerDetails from "../components/ModalPartnerDetails";
import ModalConfirmDetails from "../components/ModalConfirmDetails";
import CardNegotiations from "../components/CardNegotiations";
import { set } from "react-hook-form";
import api from "../../../services/api";
import ContractFormact from "../components/ContractFormact";
import FilterPartner from "../components/FilterPartner";
import ContractFooter from "../components/ContractFooter";
import AttachedFiles from "../components/AttachedFiles";
import { toast } from "react-toastify";
import Loading from "../components/Loading";

const Partners = () => {
  /* BUTTONS SECTION */
  const [btnActive, setBtnActive] = useState("Parceiros");
  const [modalActive, setModalActive] = useState(false);

  const [modalActiveRecommended, setModalActiveRecommended] = useState(false);
  const [modalDetails, setModalDetails] = useState("");
  const [modalDetailsName, setModalDetailsName] = useState("");

  const [modalContract, setModalContract] = useState("");
  const [confirmModalDetails, setConfirmModalDetails] = useState(false);
  const [listOpportunities, setListOpportunities] = useState([]);
  const [listRecommended, setListRecommended] = useState([]);

  const [listOpportunitiesFilter, setListOpportunitiesFilter] = useState([]);
  const [listRecommendedFilter, setListRecommendedFilter] = useState([]);
  const [listContracts, setListContracts] = useState([]);

  const [selectPartner, setSelectPartner] = useState("");
  const [modalContractId, setModalContractId] = useState("");
  const [modalContractdSignId, setModalContractdSignId] = useState("");

  const [client, setClient] = useState();
  const [partnerAccount, setPartnerAccount] = useState("");
  const [listUrlsProposal, setListUrlsProposal] = useState([]);

  const [listUrlsProposalSigned, setListUrlsProposalSigned] = useState([]);
  const [listSignedContracts, setListSignedContracts] = useState([]);
  const [disabledSignContract, setDisabledSignContract] = useState(false);

  const [signContractClient, setSignContractClient] = useState("");
  const [contractFormact, setContractFormact] = useState({});
  const [loadingAnimation, setLoadingAnimation] = useState(true);
  const [loadingAnimationContract, setLoadingAnimationContract] =
    useState(true);

  const getPartner = async () => {
    const partnerStorage = localStorage.getItem("@PARTNER_RECOMMENDED:");

    try {
      const listPartners = await api.get("/partner");
      const listOpportunities = await api.get("/opportunity/partner/all");
      if (listOpportunities.status) {
        setLoadingAnimation(false);
      }
      setListOpportunities(listOpportunities.data);
      setListRecommended(listPartners.data);

      setListOpportunitiesFilter(listOpportunities.data);
      setListRecommendedFilter(listPartners.data);

      if (partnerStorage) {
        setSelectPartner(partnerStorage);

        const filter = listPartners.data.filter(
          (item) => item.partner.type == partnerStorage
        );
        setListRecommendedFilter(filter);

        setTimeout(() => {
          localStorage.removeItem("@PARTNER_RECOMMENDED:");
        }, 500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFilterPartnerRecommended = async (value) => {
    if (value == "default") {
      return setListRecommendedFilter(listRecommended);
    }

    const filter = listRecommended.filter((item) => item.partner.type == value);
    return setListRecommendedFilter(filter);
  };

  const getFilterPartnerOpportunities = async (value) => {
    if (value == "default") {
      return setListOpportunitiesFilter(listOpportunities);
    }

    const filter = listOpportunities.filter(
      (item) => item.partner.type == value
    );
    return setListOpportunitiesFilter(filter);
  };

  const get = async () => {
    await getPartner();
  };

  const getContracts = async () => {
    try {
      const profile = await api.get("/profile");

      setClient(profile.data);

      const id = profile.data.id;

      const response = await api.get(`/customer/proposals/${id}`);

      setListContracts(response.data);
    } catch (error) {}
  };

  const getSignedContracts = async () => {
    try {
      const profile = await api.get("/profile");

      setClient(profile.data);

      const id = profile.data.id;

      const response = await api.get(`/customer/signed/proposals/${id}`);

      setListSignedContracts(response.data);
    } catch (error) {}
  };

  const getProfilePartner = async () => {
    try {
      setPartnerAccount({});
      const response = await api.get(`/partner/account/${modalContractId}`);
      const filter = listContracts.find((item) => item.id == modalContract);

      setListUrlsProposal([
        filter.proposal,
        filter.annex_one_purpose,
        filter.annex_one_scope,
        filter.annex_one_price_and_conditions,
      ]);
      setPartnerAccount(response.data);
    } catch (error) {}
  };

  const getProfilePartnerSign = async () => {
    try {
      const response = await api.get(
        `/partner/account/${modalContractdSignId}`
      );

      setPartnerAccount(response.data);
      setModalActive(true);
      setModalContractdSignId("");
    } catch (error) {}
  };

  const signContract = async () => {
    setDisabledSignContract(true);

    const dataContract = {
      contract_id: modalContract,
      client_email: client.email,
      client_name: client.name,
      partner_id: modalContractId,
      client_company: client.company,
      partner_name: partnerAccount.name,
      partner_email: partnerAccount.email,
      partner_logo: partnerAccount.picture,
      proposal: listUrlsProposal[0],
      annex_one_purpose: listUrlsProposal[1],
      annex_one_scope: listUrlsProposal[2],
      html: contractFormact,
      signature_client: { name: client?.representative, cnpj: client?.cnpj },
      signature_partner: {
        name: partnerAccount?.representative,
        cnpj: partnerAccount?.cnpj,
      },
      annex_one_price_and_conditions: listUrlsProposal[3],
    };
    try {
      console.log(dataContract.partner_id, modalContractId);
      const response = await api.post(
        "/customer/proposals/send/email",
        dataContract
      );

      setModalActive(false);

      toast.success(
        `
                Parabéns! Proposta assinada com sucesso e contrato enviado por e-mail.
            `,
        {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );

      const proposals = await api.get(`/customer/proposals/${client.id}`);

      setListContracts(proposals.data);
      setDisabledSignContract(false);
    } catch (error) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setDisabledSignContract(false);
    }
  };

  useEffect(() => {
    get();
    getContracts();
    getSignedContracts();
  }, [btnActive]);

  useEffect(() => {
    if (modalContractId) {
      getProfilePartner();
    }
  }, [modalContractId, modalContract]);

  useEffect(() => {
    if (modalContractdSignId) {
      getProfilePartnerSign();
    }
  }, [modalContractdSignId]);

  useEffect(() => {
    if (modalActive) {
      console.log(loadingAnimationContract);
    } else {
      setLoadingAnimationContract(true);
    }
  }, [loadingAnimationContract, modalActive]);

  /* STYLES */
  const container = {
    maxWidth: "100%",
    paddingTop: "2.6875rem",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginBottom: "200px",
  };

  const boxCards = {
    display: "flex",
    padding: "28px 33px",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "10px",
  };

  const boxCardsNoWrap = {
    display: "flex",
    padding: "28px 33px",
    flexDirection: "column",
    gap: "20px",
  };

  return (
    <ThowColumLayout
      page={"Partners"}
      colum1={<Sidebar page="Partners" />}
      colum2={
        <>
          <HeaderDashboard btnBack={false} name="Soluções" />

          <div className="containerBox" style={container}>
            <div
              style={{
                maxWidth: client?.permission ? "140px" : "404px",
                display: "flex",
                justifyContent: "space-between",
                border: "1px solid #D7D7D7",
                borderRadius: "100px",
                padding: "5px",
                backgroundColor: "#fff",
              }}
            >
              <Button
                height="35px"
                background="var(--color-primary)"
                borderRadius="60px"
                marginTop="0"
                name="Parceiros"
                selectBtn={btnActive}
                onClick={() => setBtnActive("Parceiros")}
                border="none"
                width="130px"
              />

              {!client?.permission && (
                <Button
                  height="35px"
                  background="var(--color-primary)"
                  borderRadius="60px"
                  marginTop="0"
                  name="Negociações"
                  selectBtn={btnActive}
                  onClick={() => setBtnActive("Negociações")}
                  border="none"
                  width="130px"
                />
              )}
              {!client?.permission && (
                <Button
                  height="35px"
                  background="var(--color-primary)"
                  borderRadius="60px"
                  marginTop="0"
                  name="Assinados"
                  selectBtn={btnActive}
                  onClick={() => setBtnActive("Assinados")}
                  border="none"
                  width="130px"
                />
              )}
            </div>

            {loadingAnimation ? (
              <Loading />
            ) : (
              (btnActive == "Parceiros" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <div
                    className="cardRecommended"
                    style={{
                      backgroundColor: "#fff",
                      border: "1px solid #D7D7D7",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      style={{
                        padding: "21px 23px 19px",
                        borderBottom: "1px solid #D7D7D7",
                        marginBottom: "28px",
                      }}
                    >
                      <h3 className="textTilteRecommended">
                        Soluções recomendados
                      </h3>
                    </div>

                    <FilterPartner
                      select={selectPartner}
                      onChangeType={(e) =>
                        getFilterPartnerRecommended(e.target.value)
                      }
                      type={listRecommended}
                      name={"Tipo:"}
                      width={"auto"}
                    />

                    <div
                      style={{
                        height: "70%",
                        overflowY: "auto",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          padding: "28px 33px",
                          justifyContent: "space-between",
                          gap: "10px",
                        }}
                      >
                        {listRecommendedFilter?.map((elem) => {
                          let description = [];

                          elem?.partner?.description.split("").map((res) => {
                            if (description.length <= 50) {
                              description.push(res);
                            } else if (description.length == 51) {
                              description = `${description.join("")}...`;
                            }
                          });

                          return (
                            <CardPartners
                              key={elem.id}
                              openModal={() => {
                                setModalActiveRecommended(true);
                                setModalDetails(elem.partner.id);
                              }}
                              image={elem.partner.picture}
                              description={description}
                              discount={elem.partner.benefits}
                              company={elem.partner.name}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      backgroundColor: "#fff",
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid #D7D7D7",
                      borderRadius: "10px",
                    }}
                    className="cardOpportunities"
                  >
                    <div
                      style={{
                        padding: "21px 23px 19px",
                        borderBottom: "1px solid #D7D7D7",
                        marginBottom: "28px",
                      }}
                    >
                      <h3 className="textTilteRecommended">Oportunidades</h3>
                    </div>

                    <FilterPartner
                      select={selectPartner}
                      onChangeType={(e) =>
                        getFilterPartnerOpportunities(e.target.value)
                      }
                      type={listRecommended}
                      name={"Tipo:"}
                      width={"auto"}
                    />
                    <div
                      style={{
                        height: "70%",
                        overflowY: "auto",
                      }}
                    >
                      <div style={boxCardsNoWrap}>
                        {listOpportunitiesFilter.map((elem) => {
                          let description = [];

                          elem?.partner?.description?.split("").map((res) => {
                            if (description.length <= 140) {
                              description.push(res);
                            } else if (description.length == 141) {
                              description = `${description.join("")}...`;
                            }
                          });

                          return (
                            <>
                              <CardPartners
                                type={"opportunities"}
                                key={elem.id}
                                openModal={() => {
                                  setModalActive(true);
                                  setModalDetails(elem.partner.id);
                                  setModalDetailsName(
                                    elem?.partner?.description
                                  );
                                }}
                                image={elem.partner.picture}
                                description={description}
                                discount={elem.partner.benefits}
                                company={elem.partner.oportunity}
                              />
                            </>
                          );
                        })}

                        <ModalContainer
                          isActive={modalActive}
                          closeModal={() => {
                            setModalActive(!modalActive);
                            setConfirmModalDetails(false);
                          }}
                        >
                          {listOpportunities.map((elem) => {
                            if (
                              elem.partner.id == modalDetails &&
                              elem?.partner?.description == modalDetailsName
                            ) {
                              return confirmModalDetails ? (
                                <ModalConfirmDetails
                                  closeModal={() => {
                                    setModalActive(false);
                                    setConfirmModalDetails(false);
                                  }}
                                />
                              ) : (
                                <ModalPartnerDetails
                                  closeModal={() => {
                                    setModalActive(!modalActive);
                                    setConfirmModalDetails(false);
                                  }}
                                  form={elem.form}
                                  id={elem.partner.id}
                                  image={elem.partner.picture}
                                  company={elem.partner.name}
                                  description={elem.partner.description}
                                  discount={elem.partner.benefits}
                                  modalConfirm={() =>
                                    setConfirmModalDetails(true)
                                  }
                                />
                              );
                            }
                          })}
                        </ModalContainer>

                        <ModalContainer
                          isActive={modalActiveRecommended}
                          closeModal={() => {
                            setModalActiveRecommended(!modalActiveRecommended);
                            setConfirmModalDetails(false);
                          }}
                        >
                          {listRecommended.map((elem) => {
                            if (elem.partner.id == modalDetails) {
                              return confirmModalDetails ? (
                                <ModalConfirmDetails
                                  closeModal={() => {
                                    setModalActiveRecommended(
                                      !modalActiveRecommended
                                    );
                                    setConfirmModalDetails(false);
                                  }}
                                />
                              ) : (
                                <ModalPartnerDetails
                                  closeModal={() => {
                                    setModalActiveRecommended(
                                      !modalActiveRecommended
                                    );
                                    setConfirmModalDetails(false);
                                  }}
                                  form={elem.form}
                                  id={elem.partner.id}
                                  image={elem.partner.picture}
                                  company={elem.partner.name}
                                  description={elem.partner.description}
                                  discount={elem.partner.benefits}
                                  modalConfirm={() =>
                                    setConfirmModalDetails(true)
                                  }
                                />
                              );
                            }
                          })}
                        </ModalContainer>
                      </div>
                    </div>
                  </div>
                </div>
              )) ||
              (btnActive == "Negociações" && (
                <div
                  style={{
                    display: "flex",
                    // flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  {listContracts.length > 0 &&
                    listContracts.map((elem) => {
                      const listDocs = [
                        elem.proposal,
                        elem.annex_one_purpose,
                        elem.annex_one_scope,
                        elem.annex_one_price_and_conditions,
                      ];

                      return (
                        <CardNegotiations
                          onModal={() => {
                            setModalContractId("");
                            setModalContract(elem.id);
                            setModalActive(true);
                            setModalContractId(elem.partner_id);
                            setListUrlsProposalSigned(listDocs);
                            setSignContractClient(elem.contract);
                          }}
                          key={elem.id}
                          id={elem.id}
                          status={elem.state}
                          image={elem.picture}
                          urls={listDocs}
                        />
                      );
                    })}

                  <ModalContainer
                    isActive={modalActive}
                    closeModal={() => {
                      setModalActive(!modalActive);
                      setConfirmModalDetails(false);
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #D7D7D7",
                        borderRadius: "10px",
                        height: "679px",
                        width: "754px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "22px",
                          borderBottom: "1px solid #D7D7D7",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "21px",
                          }}
                        >
                          <svg
                            onClick={() => setModalActive(!modalActive)}
                            cursor={"pointer"}
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.48406 6.36766L12.4871 1.35916C12.7976 1.04823 12.7976 0.544109 12.4871 0.2332C12.1765 -0.0777335 11.673 -0.0777335 11.3624 0.2332L6.35997 5.24222L1.35752 0.2332C1.04696 -0.0777335 0.543453 -0.0777335 0.232919 0.2332C-0.0776148 0.544134 -0.0776396 1.04825 0.232919 1.35916L5.23589 6.36766L0.232919 11.3762C-0.0776396 11.6871 -0.0776396 12.1912 0.232919 12.5021C0.543478 12.8131 1.04699 12.8131 1.35752 12.5021L6.35997 7.4931L11.3624 12.5021C11.673 12.8131 12.1765 12.8131 12.487 12.5021C12.7976 12.1912 12.7976 11.6871 12.487 11.3762L7.48406 6.36766Z"
                              fill="black"
                            />
                          </svg>
                          <p className="textTitleCloseModal">
                            Assine o contrato digitalmente
                          </p>
                        </div>
                        <Button
                          onClick={() => signContract()}
                          disabled={disabledSignContract}
                          height="33px"
                          background="var(--color-primary)"
                          borderRadius="60px"
                          marginTop="0"
                          name="Assinar"
                          selectBtn={"Assinar"}
                          border="none"
                          width="134px"
                        />
                      </div>

                      <div
                        style={{
                          height: "84%",
                          overflowY: "scroll",
                          display: "flex",
                        }}
                      >
                        <div>
                          <ContractFormact
                            id={modalContractId}
                            setContractFormact={setContractFormact}
                            name={client?.name}
                            company={client?.company}
                            cnpj={client?.cnpj}
                            phone={client?.phone}
                            address={client?.address}
                            cpf={client?.cpf}
                            rg={client?.rg}
                            representative={client?.representative}
                            nationality={client?.nationality}
                            marital={client?.marital_status}
                            profession={client?.profession}
                            setLoading={setLoadingAnimationContract}
                            partner={partnerAccount}
                          />

                          {loadingAnimationContract ? (
                            <Loading absolute={true} />
                          ) : (
                            <>
                              <AttachedFiles urls={listUrlsProposal} />

                              <ContractFooter
                                contractedName={client?.representative}
                                cnpj={client?.cnpj}
                                contractingCnpj={partnerAccount?.cnpj}
                                contractingName={partnerAccount?.representative}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </ModalContainer>
                </div>
              )) ||
              (btnActive == "Assinados" && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  {listSignedContracts.length > 0 &&
                    listSignedContracts.map((elem) => {
                      const listDocs = [
                        elem.proposal,
                        elem.annex_one_purpose,
                        elem.annex_one_scope,
                        elem.annex_one_price_and_conditions,
                      ];

                      return (
                        <CardNegotiations
                          onModal={() => {
                            setListUrlsProposalSigned(listDocs);
                            setModalContractdSignId(elem.partner_id);
                            setSignContractClient(elem.contract);
                          }}
                          key={elem.id}
                          id={elem.id}
                          status={elem.state}
                          image={elem.picture}
                          urls={listDocs}
                        />
                      );
                    })}

                  <ModalContainer
                    isActive={modalActive}
                    closeModal={() => {
                      setModalActive(!modalActive);
                      setConfirmModalDetails(false);
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #D7D7D7",
                        borderRadius: "10px",
                        height: "679px",
                        width: "754px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "22px",
                          borderBottom: "1px solid #D7D7D7",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "21px",
                          }}
                        >
                          <svg
                            onClick={() => setModalActive(!modalActive)}
                            cursor={"pointer"}
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.48406 6.36766L12.4871 1.35916C12.7976 1.04823 12.7976 0.544109 12.4871 0.2332C12.1765 -0.0777335 11.673 -0.0777335 11.3624 0.2332L6.35997 5.24222L1.35752 0.2332C1.04696 -0.0777335 0.543453 -0.0777335 0.232919 0.2332C-0.0776148 0.544134 -0.0776396 1.04825 0.232919 1.35916L5.23589 6.36766L0.232919 11.3762C-0.0776396 11.6871 -0.0776396 12.1912 0.232919 12.5021C0.543478 12.8131 1.04699 12.8131 1.35752 12.5021L6.35997 7.4931L11.3624 12.5021C11.673 12.8131 12.1765 12.8131 12.487 12.5021C12.7976 12.1912 12.7976 11.6871 12.487 11.3762L7.48406 6.36766Z"
                              fill="black"
                            />
                          </svg>
                          <p className="textTitleCloseModal">
                            Visualize o contrato digital
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          height: "84%",
                          overflowY: "scroll",
                          display: "flex",
                        }}
                      >
                        <div>
                          <ContractFormact
                            signContractClient={signContractClient}
                            setContractFormact={setContractFormact}
                            name={client?.name}
                            company={client?.company}
                            cnpj={client?.cnpj}
                            phone={client?.phone}
                            // contractingCnpj={partnerAccount?.cnpj}
                            partner={partnerAccount}
                          />

                          {/* {loadingAnimationContract ? (
                            <Loading absolute={true} />
                          ) : (
                            <>
                              <AttachedFiles urls={listUrlsProposalSigned} />

                              <ContractFooter
                                contractedName={client?.representative}
                                cnpj={client?.cnpj}
                                contractingCnpj={partnerAccount?.cnpj}
                                contractingName={partnerAccount?.name}
                              />
                            </>
                          )} */}
                          <>
                            <AttachedFiles urls={listUrlsProposalSigned} />

                            <ContractFooter
                              contractedName={client?.representative}
                              cnpj={client?.cnpj}
                              contractingCnpj={partnerAccount?.cnpj}
                              contractingName={partnerAccount?.representative}
                            />
                          </>
                        </div>
                      </div>
                    </div>
                  </ModalContainer>
                </div>
              ))
            )}
          </div>
        </>
      }
    ></ThowColumLayout>
  );
};

export default Partners;
