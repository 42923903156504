import confirmModalDetailsIcon from "../../../images/svg/confirmModalDetailsIcon.svg"
import Button from "./Button"

const ModalConfirmDetails = ({closeModal}) => {

    return(
        <div style={{
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            height: "589px",
            width: "513px",
            backgroundColor: "#fff",
            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "40px 43px 28px"
        }}>
            <h3 className="textTitleConfirmedInterest">Interesse confirmado</h3>
            <p className="textDescriptionConfirmedInterest">Seu interesse na cotação foi realizado com sucesso, em breve nosso parceiro entrara em contato com você.</p>
            <div>
                <img src={confirmModalDetailsIcon} />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "18px",
                marginTop: "51px"
            }}>
                <Button width="317px" height="44px" background="var(--color-primary)"
                    borderRadius="60px" marginTop="0" name="Enviar" selectBtn={"Enviar"}
                    border="none" onClick={closeModal}/>
                    <span onClick={closeModal} className="textBtnConfirmedInterest">Fechar</span>
            </div>
        </div>
    )
}

export default ModalConfirmDetails