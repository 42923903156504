import React,{createContext,useState} from 'react'

export const ModalContext = createContext({})

function ModalProvider({children}){

    const [modalGlobal,stateModalGlobal] = useState(false)
    const [deleteItems,setDeleteItems] = useState(false)

    const setStateModalGlobal = (value) => {
        stateModalGlobal(value)   
    }

    const removeAllItems = (value) => {

        setDeleteItems(value)
    
    }


    
    return(
    <ModalContext.Provider value={{setStateModalGlobal,removeAllItems,deleteItems,modalGlobal}}>
        {children}
    </ModalContext.Provider>
    )

}

export default ModalProvider