import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";

export const AuthContext = createContext({});

export const AuthValidation = ({ children }) => {
  const [checkTerms, setCheckTerms] = useState(false);
  const [email, setEmail] = useState("email@mail.com");
  const navigate = useNavigate();

  /* CLIENT */
  const onSubmitFormLoginClient = async (data) => {
    try {
      const response = await api.post("/session/client", data);
      const { token } = response.data;

      try {
        const profileToken = await api.get(`/profile/user/token/${token}`);
        const { type } = profileToken.data;

        console.log(type);

        if (type === "Funcionario") {
          localStorage.clear();
          localStorage.setItem("@QuboEmployee:", token);
          navigate("/dashboard/trail", { replace: true });
        }

        if (type === "Administrativo") {
          localStorage.clear();
          localStorage.setItem("@QuboAdmin:", token);
          navigate("/dashboard", { replace: true });
        }

        if (type === "Cliente") {
          localStorage.clear();
          localStorage.setItem("@QuboClient:", token);
          navigate("/dashboard", { replace: true });
        }
      } catch (err) {
        const message = err.response.data.error;
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      //localStorage.setItem("@QuboClient:", token);
      //navigate("/dashboard", { replace: true });
    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onSubmitFormRegisterClient = async (data) => {
    try {
      if (!checkTerms) {
        return toast.error("Aceite os termos para continuar", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      await api.post("/client", data);

      const message = "Conta criada com sucesso.";

      toast.success(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <AuthContext.Provider
        value={{
          checkTerms,
          setCheckTerms,
          email,
          onSubmitFormLoginClient,
          onSubmitFormRegisterClient,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};
