import { useState, useEffect } from "react"
import check from "../../../images/svg/check.svg"
import Button from "./Button"



const CardPlans = ({ type, price, listBenefits, details, btnValue, setBtnValue, onClick }) => {
    const [color, setColor] = useState("var(--color-primary)")

    useEffect(() => {
        if (btnValue == "Selecionar") {
            setColor("var(--color-primary)")
        }
        if (btnValue == "Cancelar plano") {
            setColor("#EA0000")
        }
    }, [btnValue])



    return (
        <div style={{
            backgroundColor: "#fff",
            borderRadius: "15px",
            width: "315px",
            border: "1px solid #d7d7d7",
            height: "410px",
            padding: "19px 24px 30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }}>
            <div>
                <div style={{
                    display: "flex",
                    borderBottom: "1px solid #d7d7d7",
                    paddingBottom: "18px",
                    justifyContent: "space-between"
                }}>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }}>
                        <p className="textPriceTitle">{type}</p>
                        <p className="textPriceContent">{price} <span className="textPriceDetails">{details}</span></p>
                    </div>
                    {
                        btnValue == "Cancelar plano" &&
                        <Button height="28px" background="var(--color-primary)" color={"#fff"}
                            borderRadius="60px" marginTop="0" name={'Plano atual'} selectBtn={'Plano atual'}
                            border={`1px solid var(--color-primary)`} className={"textBoldBtnLower"} />
                    }
                </div>
                <div style={{
                    padding: "27px 0 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "13px"
                }}>
                    {
                        listBenefits.map(item => {
                            return (
                                <div style={{
                                    display: "flex",
                                    gap: "13px"
                                }}>
                                    <img src={check} />
                                    <p>{item}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Button height="48px" background="#fff" color={color} onClick={onClick}
                borderRadius="60px" marginTop="0" name={btnValue} selectBtn={btnValue}
                border={`1px solid ${color}`} width="100%" className={"textBoldBtn"} />
        </div>
    )
}

export default CardPlans