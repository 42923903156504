import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ThowColumLayoutLogin from "../../../layouts/ThowColumLayoutLogin";
import companyIcon from "../../../images/svg/companyIcon.svg";
import registerIcon from "../../../images/svg/loginIcon.svg";
import { AuthContext } from "../../../context/Auth/AuthContext";
import Input from "../components/Input";
import Button from "../components/Button";
import TermsFooter from "../components/TermsFooter";
import { cpf as validateCPF, cnpj as validateCNPJ } from "cpf-cnpj-validator";
import { toast } from "react-toastify";

const Register = () => {
  const { onSubmitFormRegisterClient, checkTerms, setCheckTerms } =
    useContext(AuthContext);

  /* STATES */
  const [name, setName] = useState("");

  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  /* MESSAGE ERROS */
  const [mailError, setMailError] = useState("");
  const [nameError, setNameError] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const navigate = useNavigate();

  /* BTN STATE */

  const [btnState, setBtnState] = useState();

  const getLogin = async () => {
    let isValid = true; // Variável de controle para verificar se todas as validações passaram

    if (mail) {
      const regexMail = /\S+@\S+\.\S+/;

      if (!regexMail.test(mail)) {
        setMailError("email inválido");
        setBtnState(true);
        isValid = false; // Define como falso se a validação não passar
      }
    }

    if (!password) {
      setPasswordError("Insira uma senha");
      setBtnState(true);
      isValid = false;
    }

    if (!confirmPassword) {
      setConfirmPasswordError("As senhas precisam ser idênticas");
      setBtnState(true);
      isValid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("As senhas precisam ser idênticas");
      setBtnState(true);
      isValid = false;
    }

    if (!name) {
      setNameError("Insira seu nome");
      setBtnState(true);
      isValid = false;
    }

    if (!mail) {
      setMailError("Insira um email");
      setBtnState(true);
      isValid = false;
    }

    if (isValid) {
      // Verifica se todas as validações passaram
      const data = {
        name: name,
        password: password,
        password_hash: password,
        email: mail,
      };

      onSubmitFormRegisterClient(data);
    }

    if (!isValid) {
      toast.error(
        "Verifique se todos os campos foram preenchidos corretamente",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  useEffect(() => {
    let isFormValid = true;

    if (String(mail).length > 1) {
      const regexMail = /\S+@\S+\.\S+/;
      if (!regexMail.test(mail)) {
        setMailError("email inválido");
        isFormValid = false;
      } else {
        setMailError("");
      }
    }

    if (String(password).length > 1) {
      if (String(password).length <= 7) {
        setPasswordError("senha deve conter no mínimo 8 caracteres");
        isFormValid = false;
      } else {
        setPasswordError("");
      }
    }

    if (confirmPassword) {
      const comparePassword = password === confirmPassword;
      if (!comparePassword) {
        setConfirmPasswordError("As senhas precisam ser idênticas");
        isFormValid = false;
      } else {
        setConfirmPasswordError("");
      }
    }

    if (!name) {
      setNameError("");
    }

    setBtnState(!isFormValid); // Define o estado do botão com base na validação do formulário
  }, [mail, password, name, confirmPassword]);

  const container = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const styleSubTitle = {
    width: "500px",
    marginBottom: "15px",
  };

  const styleTitle = {
    width: "500px",
    marginBottom: "15px",
  };

  const styleCadastro = {
    width: "100%",
  };

  const boxFooterButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "52px",
    marginTop: "19px",
  };

  const footerLogin = {
    display: "flex",
    alignItems: "center",
    padding: "0 26px",
    justifyContent: "space-between",
  };

  const footerButton = {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    marginBottom: "55px",
  };

  const containerInputs = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const boxInputs = {
    display: "flex",
    gap: "50px",
    width: "100%",
  };

  const containerBanner = {
    position: "fixed",
    width: "45%",
    backgroundColor: "#F8F9FF",
    paddingLeft: "2vw",
    paddingRight: "2vw",
    paddingTop: "2vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const image = {
    width: "480px",
    height: "480px",
    marginTop: "-40px",
    display: "flex",
    alignItems: "center",
  };

  const containerColum2 = {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    paddingLeft: "4vw",
    paddingRight: "3vw",
    marginTop: "45px",
  };

  const styleLogo = {
    paddingTop: "2vw",
    width: "120px",
  };

  return (
    <ThowColumLayoutLogin
      page={"Register"}
      colum1={
        <div style={containerBanner}>
          {/* <h1 className='textTitle-login' style={styleTitle}>Evolua sua empresa com a Guidz </h1> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              margin: "0 0 0 100px",
            }}
          >
            <img
              src={companyIcon}
              style={{ height: "60px", marginRight: "20px" }}
            />
            <h1 className="textTitle-login" style={styleTitle}>
              Aqui começa a jornada mais importante da sua Empresa!
            </h1>
          </div>

          {/* <p className="textSubTitle-login" style={styleSubTitle}>
            Tenha acesso a uma trinha de conhecimento, faça networking com
            outros empresarios e muito mais...
          </p> */}
          <img src={registerIcon} style={image} alt="imagem inicial de login" />
        </div>
      }
      colum2={
        <div style={containerColum2}>
          <img src={companyIcon} alt="Logo Guidz" style={styleLogo} />

          <div style={container}>
            <h2 className="textPrimaryRegister" style={styleCadastro}>
              Cadastro
            </h2>

            <div style={containerInputs}>
              <div style={boxInputs}>
                <Input
                  importance={"Obrigatório"}
                  name="Seu nome"
                  error={nameError}
                  width="290px"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Insira o seu nome"
                  type="text"
                />
                <Input
                  importance={"Obrigatório"}
                  name="Email"
                  error={mailError}
                  width="290px"
                  onChange={(e) => setMail(e.target.value)}
                  placeholder="Insira seu e-mail"
                  type="email"
                />
              </div>
              <div style={boxInputs}>
                <Input
                  importance={"Obrigatório"}
                  name="Senha"
                  error={passwordError}
                  width="290px"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Insira uma senha"
                  type="password"
                />
                <Input
                  importance={"Obrigatório"}
                  name="Confirme sua senha"
                  error={confirmPasswordError}
                  width="290px"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirma a senha"
                  type="password"
                />
              </div>
            </div>

            <div style={boxFooterButton}>
              <TermsFooter
                checkTerms={checkTerms}
                setCheckTerms={setCheckTerms}
              />

              <div style={footerButton}>
                <Button
                  width="267px"
                  height="45px"
                  background="var(--color-primary)"
                  borderRadius="60px"
                  marginTop="0"
                  name="Cadastrar"
                  border="none"
                  disabled={btnState}
                  onClick={() => getLogin()}
                />

                <div style={footerLogin}>
                  <p className="textFooterRegisterTitle">Já tem uma conta?</p>
                  <p
                    onClick={() => navigate("/")}
                    style={{ cursor: "pointer" }}
                    className="textFooterRegisterContent"
                  >
                    Login
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    ></ThowColumLayoutLogin>
  );
};

export default Register;
