import React, { useEffect, useContext } from 'react'
import { BigHead } from '@bigheads/core'
import { ModalContext } from '../../../context/Modal'

const CardTableUsers = ({ checkNotVisible, name, mail, level, picture, onClick }) => {

    const { removeAllItems } = useContext(ModalContext)

    const styleColum2 = {

        maxWidth: "1079px",
        border: "1px solid #D7D7D7",
        height: "55px",
        marginTop: "1.26rem",
        borderRadius: "10px",
        background: "white"


    }

    const changeTypeBtn = () => {

        removeAllItems(checkNotVisible)

    }


    useEffect(() => {

        changeTypeBtn()
    }, [checkNotVisible])

    return (


        <div style={styleColum2}>



            <div style={{ position: "relative", top: "14px", left: "17px" }}>
                {checkNotVisible == true

                    ?
                    <input type="checkbox" style={{ cursor: "pointer" }} checked />
                    :
                    <input type="checkbox" style={{ cursor: "pointer" }} />

                }
            </div>

            <div style={{ display: "flex", justifyContent: "space-around" }}>


                <div style={{ display: "flex" }}>
                    {!picture

                        ?

                        <div style={{width: "35px", position: "relative"}}>
                            <div style={{ width: "45px", height: "35px", position: "absolute", top: "-24px", left: "10px", borderRadius: "100%" }}>
                                <BigHead
                                    accessory="shades"
                                    body="chest"
                                    circleColor="blue"
                                    clothing="tankTop"
                                    clothingColor="black"
                                    eyebrows="angry"
                                    eyes="wink"
                                    facialHair="mediumBeard"
                                    graphic="vue"
                                    hair="short"
                                    hairColor="black"
                                    hat="none"
                                    hatColor="green"
                                    lashes="false"
                                    lipColor="purple"
                                    mask="true"
                                    faceMask="true"
                                    mouth="open"
                                    skinTone="brown"
                                />
                            </div>
                        </div>

                        :
                        <img src={picture} style={{ width: "35px", height: "35px", position: "relative", top: "-14px", left: "17px", borderRadius: "100px" }} />
                    }
                    <p className="textNameTableListUserDash">{name}</p>

                </div>

                <div>
                    <p className="textNameTableListUserDash">{mail}</p>
                </div>

                <div style={{ display: "flex" }}>
                    <p className="textNameTableListUserDash">{level}</p>

                    <div style={{ position: "relative", top: "-3px", left: "10px", cursor: "pointer" }} onClick={onClick}>
                        <div style={{ width: "4px", height: "4px", background: "black", borderRadius: "100%" }}></div>
                        <div style={{ width: "4px", height: "4px", background: "black", borderRadius: "100%", marginTop: "2px" }}></div>
                        <div style={{ width: "4px", height: "4px", background: "black", borderRadius: "100%", marginTop: "2px" }}></div>

                    </div>


                </div>

            </div>


        </div>

    )

}

export default CardTableUsers