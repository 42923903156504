import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ThowColumLayoutLogin from "../../../layouts/ThowColumLayoutLogin";
import loginIcon from "../../../images/svg/loginIcon.svg";
import companyIcon from "../../../images/svg/companyIcon.svg";
import { AuthContext } from "../../../context/Auth/AuthContext";
import Button from "../components/Button";
import Input from "../components/Input";
import { toast } from "react-toastify";

const Landing = () => {
  const { onSubmitFormLoginClient } = useContext(AuthContext);
  const [mail, setMail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  /* MESSAGE ERROS */
  const [mailError, setMailError] = useState();

  /* BTN STATE */
  const [btnState, setBtnState] = useState();

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const getLogin = async () => {
    const validMail = validateEmail(mail);

    if (validMail == false) {
      setMailError("email inválido");
      setBtnState(true);
    } else {
      setMailError();
      setBtnState(false);
    }

    if (mail != undefined && password != undefined && validMail != false) {
      const data = {
        email: mail,
        password: password,
      };
      onSubmitFormLoginClient(data);
    } else {
      toast.error("Os campos de e-mail e senha são obrigatórios", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (mail != undefined) {
      const validMail = validateEmail(mail);

      if (validMail == false) {
        setMailError("email inválido");
        setBtnState(true);
      } else {
        setMailError();
        setBtnState(false);
      }
    }
  }, [mail]);

  const container = {
    width: "418px",
    marginLeft: "100px",
    // margin: "0 auto"
  };

  const styleSubTitle = {
    width: "500px",
    marginBottom: "15px",
  };

  const styleTitle = {
    width: "500px",
    marginBottom: "15px",
  };

  const boxFooterButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const containerBanner = {
    position: "fixed",
    width: "45%",
    backgroundColor: "#F8F9FF",
    paddingLeft: "2vw",
    paddingRight: "2vw",
    paddingTop: "2vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const image = {
    width: "480px",
    height: "480px",
    marginTop: "-40px",
    display: "flex",
    alignItems: "center",
  };

  const containerColum2 = {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "50%",
    height: "100vh",
  };

  return (
    <ThowColumLayoutLogin
      page={"Landing"}
      colum1={
        <div style={containerBanner}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              margin: "0 0 0 100px",
            }}
          >
            <img
              src={companyIcon}
              style={{ height: "60px", marginRight: "20px" }}
            />
            <h1 className="textTitle-login" style={styleTitle}>
              Aqui começa a jornada mais importante da sua Empresa!
            </h1>
          </div>

          {/* <p className="textSubTitle-login" style={styleSubTitle}>
            Tenha acesso a uma trinha de conhecimento, faça networking com
            outros empresarios e muito mais...
          </p> */}
          <img src={loginIcon} style={image} alt="imagem inicial de login" />
        </div>
      }
      colum2={
        <div style={containerColum2}>
          <div style={container}>
            <img src={companyIcon} />
            <h2 className="textPrimaryLanding">Dados de acesso</h2>

            <Input
              name="E-mail"
              error={mailError}
              onChange={(e) => setMail(e.target.value)}
              placeholder="Insira seu e-mail"
              type={"text"}
            />
            <Input
              name="Senha"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Insira sua senha"
              type={"password"}
            />

            <div style={boxFooterButton}>
              <Button
                width="185px"
                height="48px"
                background="var(--color-primary)"
                borderRadius="60px"
                marginTop="0"
                name="Entrar"
                border="none"
                disabled={btnState}
                onClick={() => getLogin()}
              />

              <div>
                <p
                  onClick={() => navigate("/forgot/password")}
                  style={{
                    cursor: "pointer",
                  }}
                  className="textForgotPassword"
                >
                  Esqueceu a senha?
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                justifyContent: "center",
                marginTop: "60px",
              }}
            >
              <p className="textCreateAccountTitle">Não tem uma conta?</p>
              <p
                onClick={() => navigate("/register")}
                className="textCreateAccountContent"
              >
                Cadastre-se
              </p>
            </div>
          </div>
        </div>
      }
    ></ThowColumLayoutLogin>
  );
};

export default Landing;
