import checkOnQuestions from "../../../images/svg/checkOnQuestions.svg";
import checkOffQuestions from "../../../images/svg/checkOffQuestions.svg";
import contentIcon from "../../../images/svg/content.svg";
import partherIcon from "../../../images/svg/parther.svg";
import profileUser from "../../../images/png/profileUser1.png";
import { useContext, useEffect } from "react";
import { DashboardClientContext } from "../../../context/DashboardClient";
import { BigHead } from "@bigheads/core";
import { useState } from "react";
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CardContentTrailEvolution = ({
  task,
  id,
  concluded,
  type,
  link,
  partner,
  idTask,
}) => {
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { users, onSubmitAddUserTask, onSubmitGetUserTask, update, setUpdate } =
    useContext(DashboardClientContext);

  const [userTask, setUserTask] = useState({});

  const userVerifyTask = async () => {
    const response = await onSubmitGetUserTask(id);

    setUserTask(response);
  };

  useEffect(() => {
    if (type === "positive") {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [type]);

  const userAddTask = async (idQuestion, idUser) => {
    const response = await onSubmitAddUserTask(idQuestion, idUser);
    setUserTask(response);
  };

  const checkConcluded = async () => {
    const data = {
      check: check === false ? 1 : 0,
      type: type,
    };

    console.log(data);

    try {
      await api.patch(`/question/details/${id}`, data);
      setCheck(!check);
      setUpdate(!update);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadBase64File = (base64String) => {
    base64String.map((item) => {
      const typeFile = item.type_file;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = item.content;
      console.log(typeFile);

      switch (typeFile) {
        case "application/pdf":
          a.download = `${item.name}.pdf`;
          break;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          a.download = `${item.name}.xlsx`;
          break;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          a.download = `${item.name}.docx`;
          break;
        case "application/vnd.ms-excel":
          a.download = `${item.name}.xls`;
          break;
        case "image/jpeg":
          a.download = `${item.name}.jpg`;
          break;
        case "image/png":
          a.download = `${item.name}.png`;
          break;
        case "text/plain":
          a.download = `${item.name}.txt`;
          break;
        case "text/csv":
          a.download = `${item.name}.csv`;
          break;
        case "application/json":
          a.download = `${item.name}.json`;
          break;
        case "application/xml":
          a.download = `${item.name}.xml`;
          break;
        case "application/zip":
          a.download = `${item.name}.zip`;
          break;
        default:
          a.download = `${item.name}.unknown`;
      }
      document.body.appendChild(a);
      a.click();
    });
    setLoading(false);
  };

  const linksContent = async () => {
    try {
      setLoading(true);
      const response = await api.post(
        "/settings/macro/questions/links/content",
        {
          id: idTask,
        }
      );
      console.log(response.data);
      downloadBase64File(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    userVerifyTask();
  }, [id]);

  useEffect(() => {
    if (loading) {
      var toastLoading = toast.loading(
        "Aguarde um momento seu download está sendo processado...",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } else {
      toast.dismiss(toastLoading);
    }
  }, [loading]);

  const cardQuestionsContent = {
    display: "flex",
    gap: "25px",
    alignItems: "center",
    paddingBottom: "24px",
    flexDirection: "column",
  };

  const questionsCard = {
    display: "flex",
    flexDirection: "row",
    gap: "25px",
    width: "50%",
    borderRight: "1px solid #79797950",
  };

  const userInfo = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "1.1rem",
    width: "auto",
    background: "white",
    border: "1.9px solid #d7d7d7",
    borderRadius: "60px",
    padding: "6px",
    paddingLeft: "20px",
  };

  return (
    <div
      style={{
        ...cardQuestionsContent,
        height: "auto",
        transition: "height 0.9s ease-in-out",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid #79797950",
            padding: "30px 0",
            display: "flex",
          }}
        >
          <div style={{ borderRight: "1px solid #79797950", width: "50%" }}>
            <div
              onClick={() => checkConcluded()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "25px",
                padding: "15px 10px 0",
                cursor: "pointer",
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                src={check ? checkOnQuestions : checkOffQuestions}
              />
              <p>{task}</p>
            </div>
          </div>

          <div
            style={{
              marginLeft: "40px",
              display: "flex",
              justifyContent: "space-between",
              width: "50%",
              alignItems: "center",
            }}
          >
            <div class="dropdown">
              <button
                style={userInfo}
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div
                  style={{
                    position: "relative",
                    right: "13px",
                    paddingLeft: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      minHeight: "25px",
                    }}
                  >
                    <p className="textTitleSelectUser">Delegar tarefa:</p>
                    {!userTask?.name && (
                      <p className="textTitleSelectUserSelect">Selecione</p>
                    )}
                    {userTask?.name && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {!userTask?.picture ? (
                          <div
                            style={{
                              width: "30px",
                              height: "25px",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "100%",
                                position: "absolute",
                                top: "-2px",
                              }}
                            >
                              <BigHead
                                accessory="shades"
                                body="chest"
                                circleColor="blue"
                                clothing="tankTop"
                                clothingColor="black"
                                eyebrows="angry"
                                eyes="wink"
                                facialHair="mediumBeard"
                                graphic="vue"
                                hair="short"
                                hairColor="black"
                                hat="none"
                                hatColor="green"
                                lashes="false"
                                lipColor="purple"
                                mask="true"
                                faceMask="true"
                                mouth="open"
                                skinTone="brown"
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              marginRight: "10px",
                              width: "25px",
                              height: "25px",
                            }}
                          >
                            <img
                              src={userTask.picture}
                              style={{
                                width: "20px",
                                height: "20px",
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                            />
                          </div>
                        )}

                        <p className="textTitleSelectUserOption">
                          {userTask.name}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <p className="textClientInfoHeader"></p>

                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.182668 1.78582C0.0688405 1.57763 0.0688405 1.32582 0.182668 1.11763C0.446809 0.634515 1.14065 0.634515 1.40479 1.11763L2.41937 2.97328C3.12207 4.25852 4.96793 4.25852 5.67064 2.97327L6.68521 1.11763C6.94935 0.634515 7.64319 0.634515 7.90733 1.11763C8.02116 1.32582 8.02116 1.57763 7.90733 1.78582L6.67725 4.03563C5.53942 6.11672 2.55058 6.11672 1.41275 4.03563L0.182668 1.78582Z"
                    fill="black"
                  />
                </svg>
              </button>

              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{ width: "100%" }}
              >
                {users?.map((item) => {
                  return (
                    <div
                      onClick={() => userAddTask(id, item.id)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px",
                        cursor: "pointer",
                        marginBottom: "15px",
                      }}
                    >
                      {!item?.picture ? (
                        <div
                          style={{
                            width: "25px",
                            height: "25px",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "100%",
                              position: "absolute",
                              top: "-7px",
                              left: "-2px",
                            }}
                          >
                            <BigHead
                              accessory="shades"
                              body="chest"
                              circleColor="blue"
                              clothing="tankTop"
                              clothingColor="black"
                              eyebrows="angry"
                              eyes="wink"
                              facialHair="mediumBeard"
                              graphic="vue"
                              hair="short"
                              hairColor="black"
                              hat="none"
                              hatColor="green"
                              lashes="false"
                              lipColor="purple"
                              mask="true"
                              faceMask="true"
                              mouth="open"
                              skinTone="brown"
                            />
                          </div>
                        </div>
                      ) : (
                        <img
                          src={item.picture}
                          style={{
                            width: "25px",
                            height: "25px",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                        />
                      )}
                      <p
                        style={{ marginLeft: "15px" }}
                        className="textTitleSelectUserOption"
                      >
                        {item.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div style={{ display: "flex", gap: "31px" }}>
              <div
                style={{ cursor: loading ? "progress" : "pointer" }}
                onClick={() => linksContent()}
              >
                <img src={contentIcon} />
              </div>

              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  localStorage.setItem("@PARTNER_RECOMMENDED:", partner);
                  localStorage.setItem(
                    "@PARTNER_SOLUTIONS:",
                    `Preciso de ajuda para essa tarefa: ${task}`
                  );
                  navigate("/dashboard/partners");
                }}
              >
                <img src={partherIcon} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContentTrailEvolution;
