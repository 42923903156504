import { useEffect, useState } from "react";

const FilterPartner = ({ name, width, onChangeType, type, select }) => {
  const [filterTypes, setFilterTypes] = useState([]);
  const [selectFilter, setSelectFilter] = useState("");

  useEffect(() => {
    if (type.length > 0) {
      const filter = type.map((item) => item.partner.type);
      setFilterTypes(filter);
    }
  }, [type]);

  return (
    <div
      style={{
        paddingLeft: "28px",
        display: "flex",
      }}
    >
      <div
        style={{
          border: "1px solid #d7d7d7",
          borderRadius: "50px",
          minWidth: "222px",
          height: "38px",
          display: "flex",
          alignItems: "center",
          padding: "7px 11px",
          gap: "10px",
        }}
      >
        <p className="textSelectCompanyHeader">{name}</p>

        {/* <select name="select" defaultValue={localStorage.getItem('@PARTNER_RECOMMENDED:')}  onChange={onChangeType} style={{ outline: "none", border: "none", maxWidth: width, minWidth: "80%" }}>
                    <option value="default">Todos</option>
                    <option value="Soluções Internas">Soluções Internas</option>
                    <option value="Advogado">Advogado</option>
                    <option value="RH">RH</option>
                    <option value="Conselheiros">Conselheiros</option>
                    <option value="Finanças">Finanças</option>
                    <option value="T&D">T&D</option>
                    <option value="Processos Internos">Processos Internos</option>
                    <option value="Contabilidade">Contabilidade</option>
                    <option value="Marketing">Marketing</option>
                    <option value="ERP">ERP</option>
                    <option value="Integrador/Automatização">Integrador/Automatização</option>
                    <option value="BI">BI</option>
                    <option value="Infra de TI">Infra de TI</option>
                    <option value="Pesquisa de Mercado">Pesquisa de Mercado</option>
                    <option value="Pesquisa NPS">Pesquisa NPS</option>
                    <option value="Tecnologia">Tecnologia</option>
                    <option value="Growth">Growth</option>
                    <option value="Eventos">Eventos</option>
                    <option value="Benefícios">Benefícios</option>
                    <option value="Bancos">Bancos</option>
                    <option value="FDCs">FDCs</option>
                    <option value="Imobiliário">Imobiliário</option>
                    <option value="Corretor">Corretor</option>
                    <option value="Tax and Labor">Tax and Labor</option>
                    <option value="Wealth">Wealth</option>
                    <option value="Auditoria">Auditoria</option>
                </select> */}

        <select
          name="select"
          defaultValue={localStorage.getItem("@PARTNER_RECOMMENDED:")}
          onChange={onChangeType}
          style={{
            outline: "none",
            border: "none",
            maxWidth: width,
            minWidth: "80%",
          }}
        >
          <option value="default">Todos</option>
          <option value="Jurídico">Jurídico</option>
          <option value="Pessoas">Pessoas</option>
          <option value="Estratégico">Estratégico</option>
          <option value="Processos internos">Processos internos</option>
          <option value="Finanças">Finanças</option>
          <option value="Vendas">Vendas</option>
          <option value="Marketing">Marketing</option>
          <option value="Tecnologia">Tecnologia</option>
        </select>
      </div>
    </div>
  );
};

export default FilterPartner;
