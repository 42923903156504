import React, { useState, useEffect } from 'react'

import ThowColumLayoutLogin from '../../../layouts/ThowColumLayoutLogin'
import forgotPasswordBackgrond from '../../../images/svg/forgotPasswordBackgrond.svg'
import companyIcon from '../../../images/svg/companyIcon.svg'
import mailIcon from '../../../images/svg/mail.svg'
import Button from '../components/Button'
import api from '../../../services/api'


const SendMail = () => {

    const [mail, setMail] = useState()
    const [password, setPassword] = useState()

    const container = {
        width: "418px",
        margin: "0 auto"
    }

    const boxFooterButton = {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px"
    }

    /* MESSAGE ERROS */

    const [mailError, setMailError] = useState()
    const [paswordError, setPasswordError] = useState()

    /* BTN STATE */

    const [btnState, setBtnState] = useState()


    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    const getLogin = async () => {


        if (password == undefined) {

            setPasswordError("senha deve conter no mínimo 8 caracteres")

        }


        const validMail = validateEmail(mail)

        if (validMail == false) {
            setMailError("email inválido")
            setBtnState(true)

        } else {
            setMailError()
            setBtnState(false)

        }


        if (String(password).length <= 7) {

            setPasswordError("senha deve conter no mínimo 8 caracteres")
            setBtnState(true)

        } else {

            setBtnState(false)
            setPasswordError()

        }




        if (mail != undefined && password != undefined && validMail != false) {

            const response = await api.post("sessions", {

                "mail": mail,
                "password": password


            })


            if (response.status == 200) {


                localStorage.setItem("@USER_NAME", response.data.user.name)
                localStorage.setItem("@USER_MAIL", response.data.user.mail)
                localStorage.setItem("@USER_PICTURE", response.data.user.picture)

                window.location.href = "/dashboard"

            }

            window.alert("email ou senha inválidos!")

        }
    }

    const boxIconMail = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "3.25rem",
    }

    const containerLogo = {
        width: "418px",
        display: "flex",
        justifyContent: "center",
        margin: "0 auto"
    }

    const containerFigure = {
        height: "100vh",
        width: "48vw",
        backgroundColor: "#F8F9FF"
    }

    const image = {
        height: "100%",
    },


        containerColum2 = {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center"
        }

    useEffect(() => {

        if (mail != undefined) {

            const validMail = validateEmail(mail)

            if (validMail == false) {
                setMailError("email inválido")
                setBtnState(true)

            } else {
                setMailError()
                setBtnState(false)

            }
        }

        if (password != undefined) {

            if (String(password).length <= 7) {

                setPasswordError("senha deve conter no mínimo 8 caracteres")
                setBtnState(true)

            } else {

                setBtnState(false)
                setPasswordError()

            }

        }


    }, [mail, password])

    return (

        <ThowColumLayoutLogin
            page={"SendMail"}
            colum1={
                <div style={containerFigure}>
                    <img src={forgotPasswordBackgrond} style={image} />
                </div>
            }

            colum2={

                <div style={containerColum2}>

                    <div style={containerLogo}>
                        <img src={companyIcon} />
                    </div>

                    <div style={container}>
                        <h2 className="textPrimarySendMail">E-mail enviado!</h2>

                        <p className="textDescriptionSendMail">Um link de recuperação de senha foi enviado para o e-mail {"marcela@startpn.com"}</p>

                        <div style={boxIconMail}>
                            <img src={mailIcon} />
                        </div>

                        <div style={boxFooterButton}>
                            <Button width="202px" height="48px" background="var(--color-primary)"
                                borderRadius="60px" marginTop="0" name="Recuperar senha"
                                border="none" disabled={btnState} onClick={() => getLogin()} />

                            <Button width="202px" height="48px" background="#fff" color="var(--color-primary)"
                                borderRadius="60px" marginTop="0" name="Reenviar e-mail"
                                border="1px solid var(--color-primary)" disabled={btnState} onClick={() => getLogin()} />
                        </div>
                    </div>

                </div>

            }


        >

        </ThowColumLayoutLogin>

    )

}

export default SendMail