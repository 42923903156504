import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";

export const DashboardClientContext = createContext({});

export const DashboardClientValidation = ({ children }) => {
  const location = useLocation();

  const [journey, setJourney] = useState([]);
  const [initialQuestions, setInitialQuestions] = useState([]);
  const [initialQuestionsResponse, setInitialQuestionsResponse] = useState([]);

  const [journeyResponse, setJourneyResponse] = useState([]);
  const [macro, setMacro] = useState([]);
  const [column, setColumn] = useState([]);

  const [listTrailEvolution, setListTrailEvolution] = useState([]);
  const [users, setUsers] = useState([]);
  const [update, setUpdate] = useState(false);

  /* GET INITIAL QUESTIONS */
  useEffect(() => {
    const token = localStorage.getItem("@QuboClient:");

    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const getInitialQuestions = async () => {
      try {
        const listInitialQuestions = await api.get("/initial/question", config);
        setInitialQuestions(listInitialQuestions.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getInitialQuestionsResponse = async () => {
      try {
        const listInitialQuestionsResponse = await api.get(
          "/initial/question/response/client",
          config
        );
        setInitialQuestionsResponse(listInitialQuestionsResponse.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getJourneyResponse = async () => {
      try {
        const listJourneyResponse = await api.get(
          "/journey/response/client",
          config
        );
        setJourneyResponse(listJourneyResponse.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getJourney = async () => {
      try {
        const listJourney = await api.get("/journey", config);
        setJourney(listJourney.data);
      } catch (error) {
        console.log(error);
      }
    };

    location.pathname === "/dashboard" && getJourney();
    location.pathname === "/dashboard" && getJourneyResponse();
    location.pathname === "/dashboard" && getInitialQuestions();
    location.pathname === "/dashboard" && getInitialQuestionsResponse();
  }, [location]);

  /* GET TRAIL OF EVOLUTION */
  useEffect(() => {
    const token = localStorage.getItem("@QuboClient:");

    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const getJourney = async () => {
      try {
        const listJourney = await api.get("/journey", config);
        setJourney(listJourney.data);

        const listJourneyResponse = await api.get(
          "/journey/response/client",
          config
        );
        setJourneyResponse(listJourneyResponse.data);

        const listMacro = await api.get("/settings/macro", config);
        setMacro(listMacro.data);

        const listColumn = await api.get("/settings/column", config);
        setColumn(listColumn.data);
      } catch (error) {
        console.log(error);
      }
    };

    location.pathname && token && getJourney();
  }, [location]);

  useEffect(() => {
    const token =
      localStorage.getItem("@QuboClient:") ||
      localStorage.getItem("@QuboAdmin:") ||
      localStorage.getItem("@QuboEmployee:");

    const getTrailEvolution = async (tokenValue) => {
      try {
        const listTrail = await api.get("/settings/macro/questions");

        setListTrailEvolution(listTrail.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getTrailEvolutionEmployee = async (tokenValue) => {
      try {
        const getTask = await api.get("/user/task/question/profile/task");

        const dataTask = { tasks: getTask.data };

        const tasksUser = await api.post(
          "/settings/macro/questions/tasks",
          dataTask
        );

        setListTrailEvolution(tasksUser.data);
      } catch (error) {
        console.log(error);
      }
    };

    location.pathname == "/dashboard/trail" &&
      token &&
      getTrailEvolution(token);
  }, [location, update]);

  /* GET USERS FOR TASKS */
  useEffect(() => {
    const token = localStorage.getItem("@QuboClient:");

    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const getUsers = async () => {
      try {
        const response = await api.get("/user", config);

        setUsers(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    location.pathname == "/dashboard/trail" && token && getUsers();
  }, [location]);

  /* ADD RESPONSE INITIAL QUESTION */
  const onSubmitAddResponseInitialQuestion = async (data) => {
    const token = localStorage.getItem("@QuboClient:");

    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    try {
      await api.post("/initial/question/response/client", data, config);

      const listInitialQuestionsResponse = await api.get(
        "/initial/question/response/client",
        config
      );
      setInitialQuestionsResponse(listInitialQuestionsResponse.data);
    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  /* ADD RESPONSE JOURNEY */
  const onSubmitAddResponseJourney = async (data) => {
    try {
      await api.post("/journey/response/client", data);

      const listJourneyResponse = await api.get("/journey/response/client");
      setJourneyResponse(listJourneyResponse.data);
    } catch (err) {
      console.log(err);
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onSubmitGetByIdMacro = async (id) => {
    const token = localStorage.getItem("@QuboClient:");

    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const macroFilter = await api.get(`/settings/macro/${id}`, config);

    return macroFilter.data;
  };

  const onSubmitGetByIdColumn = async (id) => {
    const token = localStorage.getItem("@QuboClient:");

    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    try {
      const columnFilter = await api.get(`/settings/column/${id}`, config);

      return columnFilter.data;
    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  /* ADD USER TO TASK */
  const onSubmitAddUserTask = async (question_id, user_id) => {
    const token = localStorage.getItem("@QuboClient:");

    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const data = { question_id, user_id };

    try {
      const response = await api.post("/user/task/question", data, config);

      const responseUserTask = await api.get(
        `/user/task/question/${question_id}`,
        config
      );

      toast.success(`${response.data.message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return responseUserTask.data;
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  /* GET USER TASK */
  const onSubmitGetUserTask = async (id) => {
    let tokenRes = "";
    const token = localStorage.getItem("@QuboClient:");
    const tokenEmployee = localStorage.getItem("@QuboEmployee:");

    if (token) {
      tokenRes = token;
    }
    if (tokenEmployee) {
      tokenRes = tokenEmployee;
    }

    try {
      let config = {
        headers: {
          Authorization: "Bearer " + tokenRes,
        },
      };

      const response = await api.get(`/user/task/question/${id}`, config);

      return response.data;
    } catch (err) {
      const message = err.response.data.message;
      console.log(err);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <DashboardClientContext.Provider
        value={{
          initialQuestions,
          initialQuestionsResponse,
          onSubmitAddResponseInitialQuestion,
          journey,
          journeyResponse,
          onSubmitAddResponseJourney,
          macro,
          column,
          onSubmitGetByIdMacro,
          onSubmitGetByIdColumn,
          listTrailEvolution,
          users,
          onSubmitAddUserTask,
          onSubmitGetUserTask,
          update,
          setUpdate,
        }}
      >
        {children}
      </DashboardClientContext.Provider>
    </>
  );
};
