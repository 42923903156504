import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./styles/animations.css";
import "./styles/hover.css";
import "./styles/active.css";
import { RoutesUrl } from "./routes/routes";
import { AuthValidation } from "./context/Auth/AuthContext";
import { ToastContainer } from "react-toastify";
import UserProvider from "./context/User";
import ModalProvider from "./context/Modal";
import { DashboardClientValidation } from "./context/DashboardClient";

const App = () => {
  return (
    <>
    <DashboardClientValidation>
        <UserProvider>
          <ModalProvider>
            <AuthValidation>
              <RoutesUrl />
            </AuthValidation>
          </ModalProvider>
        </UserProvider>
    </DashboardClientValidation>
      <ToastContainer />
    </>
  );
};

export default App;
