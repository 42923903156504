import React, { useState, useContext, useEffect } from 'react'
import CardTableUsers from './CardTableUsers'
import uploadUserIcon from '../../../images/svg/uploadUserIcon.svg'
import Input from './Input'
import InputSelectFilterManager from './InputSelectFilterManager'
import { ModalContext } from '../../../context/Modal';
import { UserContext } from '../../../context/User';
import Modal from 'react-bootstrap/Modal';
import app from '../../../firebase/database';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../services/api'


const TableListUser = () => {

    const { users, onSubmitAddUser, onSubmitDeleteUser, onSubmitUpdateUser } = useContext(UserContext)

    const [stateCheckPrimary, setStateCheckPrimary] = useState(false)
    const [idUser, setIdUser] = useState("")
    const [clientId, setClientId] = useState("")

    const [image, setImage] = useState()
    const [imagePreview, setImagePreview] = useState()
    const [imageFirebase, setImageFirebase] = useState()

    /* MODAL STATES */

    const { modalGlobal, setStateModalGlobal } = useContext(ModalContext)

    const [modalActions, setModalActions] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [modalConfirmation, setModalConfirmation] = useState(false)

    const [name, setName] = useState()
    const [mail, setMail] = useState()
    const [password, setPassword] = useState()

    const [permission, setPermission] = useState()

    /* ERROR MESSAGES */

    const [nameError, setNameError] = useState()
    const [mailError, setMailError] = useState()
    const [passwordError, setPasswordError] = useState()

    const [permissionError, setPermissionError] = useState()

    /* ID */
    const [id, setId] = useState()

    const loadImage = (file) => {

        setImage(file)
        setImageFirebase(file)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
            setImagePreview(reader.result)
        }
    }

    const update = async (data) => {
        setModalActions(true)
        setId(data.id)
        setName(data.name)
        setMail(data.email)
        setImage(data.picture)
        setPermission(data.permission)

    }

    const onUpdate = async () => {

        if (imageFirebase) {
            imageFirebase && await app.storage().ref(`/users/${imageFirebase.name}`).put(imageFirebase).then(() => {
                app.storage().ref(`/users/${imageFirebase.name}`).getDownloadURL().then(async (url) => {
                    console.log(url)
                    setImageFirebase()
                    if (!password) {
                        if (imageFirebase) {
                            const data = {
                                picture: url,
                                name: name,
                                email: mail,
                                permission: permission,
                            }
                            return await onSubmitUpdateUser(idUser, data, closeModalEdit)
                        }

                        const data = {
                            name: name,
                            email: mail,
                            permission: permission,
                        }

                        return await onSubmitUpdateUser(idUser, data, closeModalEdit)
                    }

                    if (password) {
                        if (imageFirebase) {
                            const data = {
                                picture: url,
                                name: name,
                                email: mail,
                                password: password,
                                password_hash: password,
                                permission: permission,
                            }
                            return await onSubmitUpdateUser(idUser, data, closeModalEdit)
                        }

                        const data = {
                            name: name,
                            email: mail,
                            password: password,
                            password_hash: password,
                            permission: permission,
                        }

                        return await onSubmitUpdateUser(idUser, data, closeModalEdit)
                    }
                })
            })

        } else {
            if (!password) {
                const data = {
                    name: name,
                    email: mail,
                    permission: permission,
                }
                return await onSubmitUpdateUser(idUser, data, closeModalEdit)
            }

            if (password) {
                const data = {
                    name: name,
                    email: mail,
                    password: password,
                    password_hash: password,
                    permission: permission,
                }

                return await onSubmitUpdateUser(idUser, data, closeModalEdit)
            }
        }
    }

    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const storage = async () => {
        const tokenClient = localStorage.getItem("@QuboClient:")

        if (tokenClient) {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + tokenClient
                }
            }

            const response = await api.get("/profile", config)
            setClientId(response.data.id)

            if (!name || !password || !permission || !validateEmail(mail)) {
                if (!name) {
                    return toast.error("Campo nome é obrigátório", {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }


                if (!validateEmail(mail)) {
                    return toast.error("Digite um email valido", {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                if (!password) {
                    return toast.error("Digite uma senha", {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                if (!permission) {
                    return toast.error("De uma permissão ao usuario", {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }

            if (imageFirebase) {
                imageFirebase && await app.storage().ref(`/users/${imageFirebase.name}`).put(imageFirebase).then(() => {
                    app.storage().ref(`/users/${imageFirebase.name}`).getDownloadURL().then(async (url) => {
                        const data = {
                            picture: url,
                            name: name,
                            password: password,
                            password_hash: password,
                            permission: permission,
                            email: mail,
                            client_id: response.data.id
                        }

                        console.log(clientId)
                        await onSubmitAddUser(data, setStateModalGlobal)
                    })
                })

            } else {

                const data = {
                    picture: '',
                    name: name,
                    password: password,
                    password_hash: password,
                    permission: permission,
                    email: mail,
                    client_id: response.data.id
                }

                console.log(clientId)

                await onSubmitAddUser(data, setStateModalGlobal)

            }

        } else {

        }
    }

    const deleteUser = async () => {
        await onSubmitDeleteUser(idUser, setStateModalGlobal)

        setModalActions(false)
        setModalConfirmation(false)
    }

    const getModalConfirmation = () => {

        setModalActions(false)
        setModalConfirmation(true)

    }

    const closeModal = (value) => {

        setName()
        setMail()
        setPassword()
        setPermission()
        setImagePreview()

        setNameError()
        setMailError()
        setPasswordError()
        setPermissionError()

        setStateModalGlobal(value)

    }

    const closeModalEdit = () => {

        setName()
        setMail()
        setPassword()
        setPermission()
        setImagePreview()

        setNameError()
        setMailError()
        setPasswordError()
        setPermissionError()
        setModalEdit(false)
    }

    const edit = async () => {
        setModalActions(false)
        setModalEdit(true)

    }

    const styleColum1 = {

        maxWidth: "1079px",
        border: "1px solid #D7D7D7",
        height: "55px",
        borderRadius: "10px",
        background: "white"

    }
    const btnStyleModal = {

        border: "none",
        outline: "none",
        background: "#123164",
        borderRadius: "50px",
        width: "101px",
        height: "36px",
    }
    const btnStyleRemove = {

        width: "82px",
        height: "37px",
        borderRadius: "50px",
        border: "none",
        background: "#FFE1E1"

    }

    return (

        <div className='containerBox' style={{ height: "100vh" }}>
            <ToastContainer />

            <div style={styleColum1}>


                <div style={{ position: "relative", top: "14px", left: "17px" }}>
                    <input type="checkbox" style={{ cursor: "pointer" }} />
                </div>


                <div style={{ display: "flex", justifyContent: "space-around" }}>

                    <div >
                        <p className="textNameTableListUserDash">Nome do usuário</p>
                    </div>

                    <div >
                        <p className="textNameTableListUserDash">E-mail</p>
                    </div>

                    <div >
                        <p className="textNameTableListUserDash">Permissão</p>
                    </div>

                </div>
            </div>

            {users.map(item => {

                return (

                    <CardTableUsers onClick={() => {
                        update(item)
                        setIdUser(item.id)
                    }} picture={item.picture} name={item.name} mail={item.email} level={item.permission} checkNotVisible={stateCheckPrimary} />


                )

            })}


            <Modal
                dialogClassName="modal-filter-table-list-user"
                show={modalGlobal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >

                    <div style={{ display: "flex", height: "30px" }}>
                        <div onClick={() => closeModal(false)} style={{ cursor: "pointer" }}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_0_2887)">
                                    <path d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z" fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_2887">
                                        <rect width="12.72" height="12.7354" fill="white" transform="translate(0.0143433)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <p className="textPrimaryModalFilterManager">Novo usuário</p>
                    </div>

                    <button className="textBtnFilterManagerModal" onClick={() => storage()} style={btnStyleModal} >
                        Criar
                    </button>


                </Modal.Header>
                <Modal.Body>

                    <input onChange={(e) => loadImage(e.target.files[0])} accept='image/png' name="file" id="file" type="file" style={{ display: "none" }} />

                    {imagePreview != undefined

                        ?

                        <label htmlFor='file' style={{ display: "flex", justifyContent: "center", marginBottom: "3.93rem", marginTop: "2.12rem" }}>
                            <img src={"data:image/;base64;" + imagePreview} style={{ width: "120px", height: "120px", borderRadius: "100%" }} />
                        </label>

                        :

                        <label htmlFor='file' style={{ display: "flex", justifyContent: "center", marginBottom: "3.93rem" }}>
                            <img src={uploadUserIcon} />
                        </label>

                    }


                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <div style={{ position: "relative" }}>
                            <Input onChange={(e) => setName(e.target.value)} value={name} color="black" width="265px" height="45px" name="Nome do usuário" />
                            <p className="errorMessage">{nameError != undefined ? nameError : <></>}</p>

                        </div>

                        <div style={{ position: "relative" }}>
                            <Input onChange={(e) => setMail(e.target.value)} value={mail} color="black" width="265px" height="45px" name="E-mail" />
                            <p className="errorMessage">{mailError != undefined ? mailError : <></>}</p>

                        </div>

                    </div>

                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <div style={{ position: "relative" }}>
                            <Input onChange={(e) => setPassword(e.target.value)} value={password} type="password" topEye="46px" rightEye="10px" color="black" width="265px" height="45px" name="Senha" />
                            <p className="errorMessage">{passwordError != undefined ? passwordError : <></>}</p>

                        </div>

                        <div style={{ position: "relative" }}>
                            <InputSelectFilterManager onChange={(e) => setPermission(e.target.value)} value={permission} type="addUser" marginRight="0" top="44px" width="265px" height="45px" name="Permissão" custom={true} />
                            <p className="errorMessage">{permissionError != undefined ? permissionError : <></>}</p>

                        </div>

                    </div>


                </Modal.Body>



            </Modal>

            <Modal
                dialogClassName="modal-filter-table-list-user-edit"
                show={modalActions}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >

                    <div style={{ display: "flex", height: "30px", justifyContent: "space-between", width: "100%" }}>

                        <p className="textPrimaryModalFilterManager" style={{ position: "relative", right: "17px" }}>Ações</p>

                        <div onClick={() => setModalActions(false)} style={{ cursor: "pointer" }}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_0_2887)">
                                    <path d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z" fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_2887">
                                        <rect width="12.72" height="12.7354" fill="white" transform="translate(0.0143433)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                    </div>


                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>

                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>

                        <div onClick={() => edit()} style={{ height: "55px", width: "100%", borderBottom: "1px solid #D7D7D7", textAlign: "center", paddingTop: "0.9rem", cursor: "pointer" }}>
                            <p className="textModalTableListUserEditPrimary">Editar acesso</p>
                        </div>

                        <div onClick={() => getModalConfirmation()} style={{ height: "55px", width: "100%", borderBottom: "1px solid #D7D7D7", textAlign: "center", paddingTop: "0.9rem", cursor: "pointer" }}>
                            <p className='textModalTableListUserEditSecondary'>Excluir acesso</p>
                        </div>

                        <div style={{ height: "70px" }}></div>

                    </div>

                </Modal.Body>



            </Modal>

            <Modal
                dialogClassName="modal-filter-table-list-user"
                show={modalEdit}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >

                    <div style={{ display: "flex", height: "30px" }}>
                        <div onClick={() => closeModalEdit(false)} style={{ cursor: "pointer" }}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_0_2887)">
                                    <path d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z" fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_2887">
                                        <rect width="12.72" height="12.7354" fill="white" transform="translate(0.0143433)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <p className="textPrimaryModalFilterManager">Editar usuário</p>
                    </div>

                    <button className="textBtnFilterManagerModal" onClick={() => onUpdate()} style={btnStyleModal} >
                        Editar
                    </button>


                </Modal.Header>
                <Modal.Body>

                    <input onChange={(e) => loadImage(e.target.files[0])} accept='image/png' name="file" id="file" type="file" style={{ display: "none" }} />

                    {imagePreview != undefined

                        ?

                        <label htmlFor='file' style={{ display: "flex", justifyContent: "center", marginBottom: "3.93rem", marginTop: "2.12rem" }}>
                            <img src={imagePreview} style={{ width: "120px", height: "120px", borderRadius: "100%" }} />
                        </label>

                        :

                        <label htmlFor='file' style={{ display: "flex", justifyContent: "center", marginBottom: "3.93rem" }}>
                            <img style={{ width: "120px", height: "120px", borderRadius: "100%" }} src={image ? image : uploadUserIcon} />
                        </label>

                    }


                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <div style={{ position: "relative" }}>
                            <Input value={name} onChange={(e) => setName(e.target.value)} color="black" width="265px" height="45px" name="Nome do usuário" />
                            <p className="errorMessage">{nameError != undefined ? nameError : <></>}</p>

                        </div>

                        <div style={{ position: "relative" }}>
                            <Input value={mail} onChange={(e) => setMail(e.target.value)} color="black" width="265px" height="45px" name="E-mail" />
                            <p className="errorMessage">{mailError != undefined ? mailError : <></>}</p>

                        </div>

                    </div>

                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <div style={{ position: "relative" }}>
                            <Input onChange={(e) => setPassword(e.target.value)} type="password" topEye="46px" rightEye="10px" color="black" width="265px" height="45px" name="Senha" />
                            <p className="errorMessage">{passwordError != undefined ? passwordError : <></>}</p>

                        </div>

                        <div style={{ position: "relative" }}>
                            <InputSelectFilterManager value={permission} onChange={(e) => setPermission(e.target.value)} type="addUser" marginRight="0" top="44px" width="265px" height="45px" name="Permissão" custom={true} />
                            <p className="errorMessage">{permissionError != undefined ? permissionError : <></>}</p>

                        </div>

                    </div>


                </Modal.Body>



            </Modal>

            <Modal
                dialogClassName="modal-filter-table-list-user-edit"
                show={modalConfirmation}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >

                    <div style={{ display: "flex", height: "30px", justifyContent: "space-between", width: "100%" }}>

                        <div style={{ display: "flex", alignItems: "center" }}>


                            <div onClick={() => setModalConfirmation(false)} style={{ cursor: "pointer" }}>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_2887)">
                                        <path d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_2887">
                                            <rect width="12.72" height="12.7354" fill="white" transform="translate(0.0143433)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <p className="textPrimaryModalFilterManager" style={{ left: "4px", top: "10px" }}>Excluir usuário</p>

                        </div>

                        <button className="textBtnRemove" onClick={() => deleteUser()} style={btnStyleRemove}>Excluir</button>

                    </div>


                </Modal.Header>
                <Modal.Body style={{ padding: "17px" }}>

                    <p className="textConfirmationRemove">Tem certeza que deseja excluir o usuário <b>{name}</b> ?</p>
                    <div style={{ height: "40px" }}></div>
                </Modal.Body>



            </Modal>


        </div>



    )
}

export default TableListUser