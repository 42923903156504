import React, { useState, useEffect } from 'react'

const InputSelectFilterManager = ({ name, width, top, custom, color, marginLeft, height, type, onChange, marginRight, value }) => {

    const [users, setUsers] = useState([])

    const getUsers = async () => {

        /* const response = await api.get("user")
         setUsers(response.data)*/


    }

    const styleSelect = {

        width: width != undefined ? width : "100%",
        height: height != undefined ? height : "50px",
        color: color != undefined ? color : "",
        borderRadius: "10px",
        background: "white",
        paddingLeft: "12px",
        border: "1px solid #D7D7D7",
        outline: "none",
        marginBottom: "1.6875rem",
        appearance: "none",
    }

    useEffect(() => {

        getUsers()

    }, [])

    return (

        <div style={{ position: "relative", width: `${width != undefined ? width : "100%"}`, display: "flex", flexDirection: "column", marginRight: `${marginRight != undefined ? marginRight : "0"}`, marginLeft: marginLeft }}>

            {custom == true

                ?
                <label style={{ marginBottom: "14px" }} className="textPrimaryLabelInput">{name}</label>

                :
                <label className="textLabelInputSelectFilterManager">{name}</label>
            }

            <select defaultValue={value} onChange={onChange} className="textSelectFilterManager" style={styleSelect}>
                <option value="">Selecione</option>
                <option value="Funcionario">Funcionario</option>
                <option value="Administrativo">Administrativo</option>
            </select>

            <div style={{ position: "absolute", right: 0, top: `${top != undefined ? top : "19px"}`, right: "19px" }}>
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.60677 6.61C5.15396 6.61 4.70115 6.40242 4.35831 5.99494L0.140695 0.982178C-0.0468983 0.759218 -0.0468983 0.39018 0.140695 0.16722C0.328288 -0.05574 0.638787 -0.05574 0.826381 0.16722L5.04399 5.17998C5.35449 5.54902 5.85905 5.54902 6.16955 5.17998L10.3872 0.16722C10.5748 -0.05574 10.8853 -0.05574 11.0729 0.16722C11.2604 0.39018 11.2604 0.759218 11.0729 0.982178L6.85524 5.99494C6.5124 6.40242 6.05959 6.61 5.60677 6.61Z" fill="#888888" />
                </svg>

            </div>



        </div>
    )

}

export default InputSelectFilterManager