import React from 'react';

const ContractFooter = ({ contractedName, contractingName, cnpj, contractingCnpj}) => {
    
    const footerStyle = {
        padding: '20px',
        marginTop: '20px',
        textAlign: 'center',
        marginBottom: '20px'
    };

    return (
        <div style={footerStyle}>
            <div style={{ marginBottom: "50px" }}>
                <p className='signatureContract' style={{ marginTop: "20px" }}>{contractingName}</p>
                <hr style={{ border: '1px solid black', width: '60%', margin: '2px auto 10px auto' }} />
                <p className='signatureContractDetails'> CNPJ nº {contractingCnpj}</p>
                <p className='signatureContractDetailsTitle'>CONTRATADA</p>
            </div>

            <div>
                <p className='signatureContract' style={{ marginTop: "20px" }}>{contractedName}</p>
                <hr style={{ border: '1px solid black', width: '60%', margin: '2px auto 10px auto' }} />
                <p className='signatureContractDetails'> CNPJ nº {cnpj}</p>
                <p className='signatureContractDetailsTitle'>CONTRATANTE</p>
            </div>

        </div>
    );
};

export default ContractFooter;
