const Textarea = ({ name, width, height, placeholder, onChange, value }) => {
  const containerTextarea = {
    display: "flex",
    flexDirection: "column",
    gap: "14.43px",
  };

  const textareaStyle = {
    width: width,
    height: height,
    resize: "none",
    padding: "15px",
    outline: "none",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    fontSize: "15px",
    color: "#5c5b5b",
  };

  return (
    <div style={containerTextarea}>
      <label className="textPrimaryLabelInput">{name}</label>
      <textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={textareaStyle}
      />
    </div>
  );
};

export default Textarea;
