import React, { useState } from 'react'
import ThowColumLayout from '../../../layouts/ThowColumLayout'
import Sidebar from '../components/Sidebar'
import HeaderDashboard from '../components/HeaderDashboard'
import TableListUser from '../components/TableListUser'
import FilterUsers from '../components/FilterUsers'

const ManageAccesses = () => {

    /* STYLES */
    const container = {
        maxWidth: "100%",
        paddingRight: "4.125rem",
        paddingTop: "2.6875rem",
        paddingLeft: "3.375rem",
        backgroundColor: "#F9F9F9",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginBottom: "20px"
    }

    return (

        <ThowColumLayout
            page={"ManageAccesses"}
            colum1={
                <Sidebar page="ManageAccesses" />
            }

            colum2={
                <>
                    <HeaderDashboard name="Gerenciar acessos"/>
                    <div className='boxContainerCard' style={container}>
                        <FilterUsers />
                        <TableListUser />
                    </div>
                </>
            }


        >

        </ThowColumLayout>

    )

}

export default ManageAccesses