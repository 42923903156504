import { useState } from "react";
import pdfIcon from "../../../images/png/pdf.png";

const AttachedFiles = ({ urls }) => {
  const [showAnexoI, setShowAnexoI] = useState(true);
  const [showAnexoII, setShowAnexoII] = useState(true);
  const [showAnexoIII, setShowAnexoIII] = useState(true);

  const footerStyle = {
    padding: "20px",
  };

  const containerAnexo = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };

  const anexo = {
    width: "50px",
    height: "50px",
    objectFit: "contain",
    // border: "1px solid #d7d7d7",
    borderRadius: "8px",
    cursor: "pointer",
  };

  return (
    // DONWLOAD
    // <div style={footerStyle}>
    //     <p className="signatureContractDetailsTitleFiles">ANEXOS:</p>
    //     <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", gap: "10px" }}>
    //         <a style={{ textDecoration: "none" }} target="_blank" href={urls[1]} download>
    //             <p className="signatureContractDetailsFiles">Anexo I - Objeto</p>
    //         </a>
    //         <a style={{ textDecoration: "none" }} target="_blank" href={urls[2]} download>
    //             <p className="signatureContractDetailsFiles">Anexo II - Escopo</p>
    //         </a>
    //         <a style={{ textDecoration: "none" }} target="_blank" href={urls[3]} download>
    //             <p className="signatureContractDetailsFiles">Anexo III - Preço e Condições</p>
    //         </a>
    //     </div>
    // </div>

    <div style={footerStyle}>
      <p className="signatureContractDetailsTitleFiles">ANEXOS:</p>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div style={containerAnexo}>
          <p
            onClick={() => setShowAnexoI(!showAnexoI)}
            className="signatureContractDetailsFiles"
          >
            Anexo I - Objeto
          </p>
          {showAnexoI && <div dangerouslySetInnerHTML={{ __html: urls[1] }} />}
        </div>

        <div style={containerAnexo}>
          <p
            onClick={() => setShowAnexoII(!showAnexoII)}
            className="signatureContractDetailsFiles"
          >
            Anexo II - Escopo
          </p>
          {showAnexoII && <div dangerouslySetInnerHTML={{ __html: urls[2] }} />}
        </div>

        <div style={containerAnexo}>
          <p
            onClick={() => setShowAnexoIII(!showAnexoIII)}
            className="signatureContractDetailsFiles"
          >
            Anexo III - Preço e Condições
          </p>
          {showAnexoIII && (
            <div dangerouslySetInnerHTML={{ __html: urls[3] }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AttachedFiles;
