
import 'chart.js/auto'; // Importe esta linha para carregar o chart.js corretamente
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const CardGraphics = ({ title, listMonths, max }) => {

    const chartId = `chart-${uuidv4()}`;
    const chartRef = useRef(null);
    const [listAverage, setListAverage] = useState([])

    const calculateRepeatedAverage = (arr) => {
        const sum = arr.reduce((total, value) => total + value, 0);
        const average = sum / arr.length;
        const newArray = Array(arr.length).fill(average);
        return newArray;
    };



    const data = {
        labels: ['Macros para concluir', 'Macros concluídos'],
        datasets: [
            {
                type: 'bar',
                data: listMonths,
                backgroundColor: ["#d7d7d7", "#102033"], // Cor das colunas
            }
        ],
    };

    const options = {
        scales: {
            y: {
                type: 'linear',
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false, // Remove a exibição da legenda
            },
            title: {
                display: true,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        elements: {
            bar: {
                borderWidth: 1,
                borderRadius: 8
            },
        },
        layout: {
            padding: {
                top: 20,
                bottom: 20,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false, // Remove a grade de fundo no eixo X
                },
            },
            y: {
                grid: {
                    display: true, // Remove a grade de fundo no eixo Y
                },
                type: 'linear',
                beginAtZero: true,
                max: max
            },
        },
    };

    useEffect(() => {
        // Cria o gráfico quando o componente é montado
        chartRef.current = new Chart(chartId, {
            type: 'bar',
            data: data,
            options: options,
        });

        return () => {
            // Destroi o gráfico quando o componente é desmontado
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, []);

    return (
        <div style={{
            height: "501px",
            width: "100%",
            backgroundColor: "#fff",
            border: "1px solid #d7d7d7",
            borderRadius: "10px",
            padding: "12px 14px"
        }}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <p className="textTitleContractualParameter">{title}</p>

            </div>
            <div style={{ height: "100%", height: "100%", display: "flex", justifyContent: "space-between" }}>

                <div style={{ height: "95%", width: "70%", minWidth: "321px", margin: "10px 0" }}>
                    <canvas id={chartId}></canvas>
                </div>

                <div style={{ marginTop: "60px", paddingRight: "30px", display: "flex", flexDirection: "column", gap: "20px" }}>

                    <div style={{ display: "flex", alignItems: "center", gap: "35px" }}>
                        <div style={{ backgroundColor: "#d7d7d7", width: "20px", height: "20px", borderRadius: "6px" }}></div>
                        <p>Macros para concluir</p>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "35px" }}>
                        <div style={{ backgroundColor: "#102033", width: "20px", height: "20px", borderRadius: "6px" }}></div>
                        <p>Macros concluidos</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardGraphics