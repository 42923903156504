import { useState } from "react";
import arrowdown from "../../../images/png/arrowdown.png";
import arrowUp from "../../../images/png/arrowUp.png";
import CardContentInitialQuestion from "./CardContentInitialQuestion";
import checkOnQuestions from "../../../images/svg/checkOnQuestions.svg";
import checkOffQuestions from "../../../images/svg/checkOffQuestions.svg";
import { useEffect } from "react";

const CardInitialQuestion = ({
  children,
  item,
  openAll,
  update,
  setUpdate,
}) => {
  const [showContent, setShowContent] = useState(false);
  const [checkRes, setCheckRes] = useState(false);

  const cardQuestionsHeader = {
    display: "flex",
    gap: "25px",
    alignItems: "center",
    borderBottom: "1px solid #D7D7D7",
    paddingBottom: "32px",
    position: "relative",
  };

  const cardQuestionsCheck = {
    position: "absolute",
    right: 0,
  };

  const verifyCheck = (state) => {
    console.log(state);
    if (state) {
      setCheckRes(true);
    }
  };

  return (
    <>
      {/* Header */}
      <div style={cardQuestionsHeader}>
        <img
          src={showContent ? arrowUp : arrowdown}
          onClick={() => setShowContent(!showContent)}
        />

        <div style={{ width: "80%" }}>
          <p>{item.name}</p>
        </div>
        <div style={{ position: "relative", height: "100%" }}>
          {checkRes ? (
            <div
              style={{
                position: "absolute",
                backgroundColor: "var(--color-primary)",
                width: "40px",
                height: "5px",
                top: -4,
                borderRadius: "60px",
              }}
            ></div>
          ) : (
            <div
              style={{
                position: "absolute",
                backgroundColor: "#d7d7d7",
                width: "40px",
                height: "5px",
                top: -4,
                borderRadius: "60px",
              }}
            ></div>
          )}
        </div>
      </div>
      <div
        style={{
          display: showContent || openAll ? "flex" : "none",
          flexDirection: "column",
        }}
      >
        {item.option.map((option) => {
          if (option.name.length > 0) {
            return (
              <CardContentInitialQuestion
                verifyCheck={verifyCheck}
                openAll={openAll}
                update={() => setUpdate(!update)}
                question={item}
                option={option}
                type={"Initial Questions"}
              />
            );
          }
        })}
      </div>
    </>
  );
};

export default CardInitialQuestion;
