import checkOff from '../../../images/svg/checkOff.svg'
import checkOn from '../../../images/svg/checkOn.svg'

const TermsFooter = ({checkTerms, setCheckTerms}) => {
    const containerTerms = {
        display: "flex",
        gap: "21px",
        flexDirection: "column"
    }

    const boxTerms = {
        display: "flex",
        gap: "15px",
        alignItems: "flex-start"
    }
    
    const boxDescriptionTerms = {
        display: "flex",
        gap: "10px",
        flexDirection: "column"
    }

    return (
        <div style={containerTerms}>
            <h3 className='textTitleTermsRegister'>Termos de uso e privacidade</h3>
            <div style={boxTerms}>
                <div style={{ cursor: "pointer" }} onClick={() => setCheckTerms(!checkTerms)}>
                    <img src={checkTerms ? checkOn : checkOff} />
                </div>

                <div style={boxDescriptionTerms}>
                    <p className='textDescriptionTermsRegister'>Ao clicar neste botão, eu concordo com os  termos de uso e privacidade da nossa empresa.</p>
                    <p className='textTermsModalRegister'>Termos de uso e privacidade</p>
                </div>
            </div>
        </div>
    )
}

export default TermsFooter