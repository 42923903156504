// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btnActive {
  background-color: var(--color-primary);
  color: var(--white);
}

.btnDisable {
  color: var(--color-primary);
  background-color: var(--white);
}
`, "",{"version":3,"sources":["webpack://./src/styles/active.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,8BAA8B;AAChC","sourcesContent":[".btnActive {\n  background-color: var(--color-primary);\n  color: var(--white);\n}\n\n.btnDisable {\n  color: var(--color-primary);\n  background-color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
