import React, { useState, useEffect } from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../components/Sidebar";
import HeaderDashboard from "../components/HeaderDashboard";
import Button from "../components/Button";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useContext } from "react";
import { DashboardClientContext } from "../../../context/DashboardClient";
import CardInitialQuestion from "../components/CardInitialQuestion";
import CardContentInitialQuestion from "../components/CardContentInitialQuestion";
import CircularSlider from "react-circular-slider-svg";
import PercentColumn from "../components/PercentColumn";
import PercentageCharts from "../components/PercentageCharts";
import api from "../../../services/api";
import CardGraphics from "../components/CardGraphics";
import Loading from "../components/Loading";

const Preview = () => {
  const { initialQuestions, journey } = useContext(DashboardClientContext);

  const [percentageScore, setPercentageScore] = useState("");
  const [messageScore, setMessageScore] = useState("");
  const [percentageMacro, setPercentageMacro] = useState({});

  const [loading, setLoading] = useState(true);

  const [journeyPercentage, setJourneyPercentage] = useState([]);
  const [macroPercentage, setMacroPercentage] = useState([]);

  /* BUTTONS SECTION */
  const [btnActive, setBtnActive] = useState("Perguntas iniciais");

  const getScorePercentage = async () => {
    try {
      const response = await api.get("/score/percentage/preview");
      setPercentageScore(response.data.percentage);
      setMessageScore(response.data.message);
    } catch (error) {
      console.log(error.response);
    }
  };

  const getMacroPercentage = async () => {
    try {
      const response = await api.get("/percentage/macro");
      setPercentageMacro(response.data);
      console.log(response.data);
    } catch (error) {}
  };

  const createScore = async () => {
    try {
      const profile = await api.get("/profile");
      console.log(profile);

      await api.patch("/score/client", {
        score: percentageScore,
        client_id: profile.data.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // getMacroPercentage()
    getScorePercentage();
  }, []);

  useEffect(() => {
    if (messageScore != "") {
      setLoading(false);
    }
  }, [journeyPercentage, macroPercentage, percentageScore]);

  useEffect(() => {
    createScore();
  }, [percentageScore]);

  /* STYLES */
  const container = {
    maxWidth: "100%",
    paddingTop: "2.6875rem",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginBottom: "20px",
  };

  const cardQuestions = {
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    height: "auto",
    padding: "33px 22px 10px",
    marginBottom: "20px",
  };

  return (
    <ThowColumLayout
      page={"Dashboard"}
      colum1={<Sidebar page="Preview" />}
      colum2={
        <>
          <HeaderDashboard name="Dashboard" btnBack={false} />

          <div className="containerBox" style={container}>
            {loading ? (
              <Loading />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="cardProgress"
                    style={{
                      backgroundColor: "#fff",
                      border: "1px solid #D7D7D7",
                      borderRadius: "10px",
                      padding: "25px 21px",
                      height: "215px",
                    }}
                  >
                    <p className="textTitleCardPercent trasnlateY">
                      Score total:
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: "28px",
                        gap: "5%",
                        borderTop: "1px solid #D7D7D7",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "30%", position: "relative" }}>
                        <div
                          style={{
                            transform: "scale(170%)",
                            position: "absolute",
                            left: "70px",
                            top: "50px",
                          }}
                        >
                          <CircularSlider
                            size={150}
                            minValue={0}
                            maxValue={100}
                            className="slider"
                            startAngle={90}
                            endAngle={270}
                            angleType={{
                              direction: "cw",
                              axis: "-y",
                            }}
                            handle1={{
                              value: percentageScore,
                              onChange: (v) => <></>,
                            }}
                            arcColor="var(--color-primary)"
                            arcBackgroundColor="#E5EAFC"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            left: "126px",
                            top: "84px",
                            width: "80px",
                          }}
                        >
                          <p className="percentageScore">
                            {parseInt(percentageScore)} %
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#fff",
                      border: "1px solid #D7D7D7",
                      borderRadius: "10px",
                      padding: "16px",
                      height: "215px",
                    }}
                    className="cardQuestions"
                  >
                    <p className="textTitleCardPercent">
                      Estágio: {messageScore?.split(":")[0]}
                    </p>
                    <div
                      style={{
                        borderTop: "1px solid #d7d7d7",
                        marginTop: "17px",
                        paddingTop: "17px",
                        height: "100%",
                        overflowX: "auto",
                        paddingRight: "10px",
                      }}
                    >
                      <p className="textMessageScore">
                        {messageScore?.split(":")[1]}
                      </p>
                    </div>
                  </div>
                </div>

                {/* {
                            percentageMacro?.macro && <CardGraphics max={percentageMacro?.macro} title={"Visão Macros"} listMonths={[percentageMacro?.macro, percentageMacro?.concluded]} />
                        }
                        {
                            !percentageMacro?.macro && <CardGraphics max={0} title={"Visão Macros"} listMonths={[0, 0]} />
                        } */}

                <PercentageCharts
                  journeyPercentage={journeyPercentage}
                  setJourneyPercentage={setJourneyPercentage}
                  title={"Visão Diagnóstico"}
                />
                <PercentageCharts
                  macroPercentage={macroPercentage}
                  setMacroPercentage={setMacroPercentage}
                  title={"Visão Jornada"}
                  type={"macro"}
                />
              </>
            )}
          </div>
        </>
      }
    ></ThowColumLayout>
  );
};

export default Preview;
