import React, { useState, useEffect } from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../components/Sidebar";
import HeaderDashboard from "../components/HeaderDashboard";
import Button from "../components/Button";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useContext } from "react";
import { DashboardClientContext } from "../../../context/DashboardClient";
import CardInitialQuestion from "../components/CardInitialQuestion";
import CardContentInitialQuestion from "../components/CardContentInitialQuestion";
import api from "../../../services/api";
import CardInitialQuestionTwo from "../components/CardInitialQuestionTwo";

const Dashboard = () => {
  const { initialQuestions, journey } = useContext(DashboardClientContext);

  /* BUTTONS SECTION */
  const [btnActive, setBtnActive] = useState("Perguntas iniciais");
  const [openAll, setOpenAll] = useState(false);

  /* PERCENTAGE */
  const [percentage, setPercentage] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);

  /* UPDATE STATE */
  const [update, setUpdate] = useState(false);

  const getPercentage = async () => {
    const tokenClient = localStorage.getItem("@QuboClient:");

    let config = {
      headers: {
        Authorization: "Bearer " + tokenClient,
      },
    };

    try {
      const percentageAllQuestions = await api.get(
        "/percentage/question",
        config
      );

      const { percentage, totalQuestions } = percentageAllQuestions.data;

      if (!percentage) {
        setPercentage(0);
      }
      if (percentage) {
        setPercentage(percentage);
      }
      setTotalQuestions(totalQuestions);
      console.log(percentageAllQuestions.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPercentage();
  }, [update]);

  /* STYLES */
  const container = {
    maxWidth: "100%",
    paddingTop: "2.6875rem",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginBottom: "20px",
  };

  const cardQuestions = {
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    height: "auto",
    padding: "33px 22px 10px",
    marginBottom: "20px",
  };

  return (
    <ThowColumLayout
      page={"Dashboard"}
      colum1={<Sidebar page="Dashboard" />}
      colum2={
        <>
          <HeaderDashboard name="Diagnóstico" btnBack={false} />

          <div className="containerBox" style={container}>
            <div
              style={{
                width: "100%",
                overflowX: "auto",
              }}
            >
              <div
                style={{
                  minWidth: `${100 * journey.length}px`,
                  display: "flex",
                  border: "1px solid #D7D7D7",
                  borderRadius: "100px",
                  padding: "5px",
                  gap: "10px",
                  marginBottom: "16px",
                  backgroundColor: "#fff",
                }}
              >
                <Button
                  padding={"0 30px"}
                  className="btnStateTrail"
                  height="35px"
                  background="var(--color-primary)"
                  borderRadius="60px"
                  marginTop="0"
                  name="Perguntas iniciais"
                  selectBtn={btnActive}
                  onClick={() => setBtnActive("Perguntas iniciais")}
                  border="none"
                />

                {journey.map((item) => {
                  return (
                    <Button
                      padding={"0 30px"}
                      className="btnStateTrail"
                      height="35px"
                      background="var(--color-primary)"
                      borderRadius="60px"
                      marginTop="0"
                      name={item.name}
                      selectBtn={btnActive}
                      onClick={() => setBtnActive(item.name)}
                      border="none"
                    />
                  );
                })}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div
                style={{
                  top: 20,
                  position: "sticky",
                  width: "299px",
                  height: "100%",
                }}
              >
                <div
                  className="cardProgress"
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid #D7D7D7",
                    borderRadius: "10px",
                    padding: "25px 21px",
                    height: "350px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingBottom: "28px",
                      gap: "5%",
                      borderBottom: "1px solid #D7D7D7",
                    }}
                  >
                    <div style={{ width: "30%", height: "auto" }}>
                      <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        background
                        backgroundPadding={6}
                        styles={buildStyles({
                          backgroundColor: "#fff",
                          textColor: "var(--color-primary)",
                          pathColor: "var(--color-primary)",
                          trailColor: "#F7F9FB",
                        })}
                      />
                    </div>

                    <p>{totalQuestions} perguntas Respondidas</p>
                  </div>

                  <div style={{ marginTop: "17px" }}>
                    <p>
                      As perguntas atuam como o guia fundamental para a Guidz
                      construir uma jornada de atividades personalizadas e
                      adaptadas às necessidades específicas da sua empresa.
                    </p>
                  </div>
                </div>

                <div
                  onClick={() => setOpenAll(!openAll)}
                  style={{
                    // backgroundColor: "var(--color-primary)",
                    color: "var(--color-primary)",
                    marginTop: "20px",
                    padding: "10px 15px",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    fontWeight: "600",
                  }}
                >
                  {openAll
                    ? "- Recolher todas as perguntas"
                    : "+ Expandir todas as perguntas"}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
                className="cardQuestions"
              >
                {btnActive == "Perguntas iniciais"
                  ? initialQuestions.map((item) => {
                      return (
                        <div style={cardQuestions}>
                          <CardInitialQuestion
                            item={item}
                            openAll={openAll}
                            update={update}
                            setUpdate={setUpdate}
                          />
                        </div>
                      );
                    })
                  : journey.map((item) => {
                      if (item.name == btnActive) {
                        return item.questions.map((quest) => {
                          return (
                            <div style={cardQuestions}>
                              <CardInitialQuestionTwo
                                item={quest}
                                openAll={openAll}
                                update={update}
                                setUpdate={setUpdate}
                              />
                            </div>
                          );
                        });
                      }
                    })}
              </div>
            </div>
          </div>
        </>
      }
    ></ThowColumLayout>
  );
};

export default Dashboard;
