import React, { useState, useEffect } from 'react'
import ThowColumLayout from '../../../layouts/ThowColumLayout'
import Sidebar from '../components/Sidebar'
import HeaderDashboard from '../components/HeaderDashboard'
import company1 from "../../../images/svg/company1.svg"
import company2 from "../../../images/svg/company2.svg"
import company3 from "../../../images/svg/company3.svg"
import company4 from "../../../images/svg/company4.svg"
import company5 from "../../../images/svg/company5.svg"
import Button from '../components/Button'
import CardPartners from '../components/CardPartners'
import ModalContainer from '../components/ModalContainer'
import ModalPartnerDetails from '../components/ModalPartnerDetails'
import ModalConfirmDetails from '../components/ModalConfirmDetails'
import CardNegotiations from '../components/CardNegotiations'
import { set } from 'react-hook-form'
import api from '../../../services/api'
import ContractFormact from '../components/ContractFormact'
import FilterPartner from '../components/FilterPartner'
import CardPlans from '../components/CardPlans'

const Plans = () => {

    /* BUTTONS SECTION */
    const [btnActive, setBtnActive] = useState("Semestral")
    const [modalActive, setModalActive] = useState(false)

    const [btnValueBasic, setBtnValueBasic] = useState('Selecionar')
    const [btnValueIntermediary, setBtnValueIntermediary] = useState('Selecionar')
    const [btnValuePlus, setBtnValuePlus] = useState('Selecionar')

    const [btnValueBasicYearly, setBtnValueBasicYearly] = useState('Selecionar')
    const [btnValueIntermediaryYearly, setBtnValueIntermediaryYearly] = useState('Selecionar')
    const [btnValuePlusYearly, setBtnValuePlusYearly] = useState('Selecionar')

    const [color, setColor] = useState("var(--color-primary)")
    const [listBenefits, setListBenefits] = useState(['Lorem ipsum dolor sit amet', 'Lorem ipsum dolor sit amet', 'Lorem ipsum dolor sit amet', 'Lorem ipsum dolor sit amet'])

    /* STYLES */
    const container = {
        maxWidth: "100%",
        paddingTop: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        marginBottom: "200px",
    }

    const handleBtnValue = (number) => {

        if (number == 1) {
            if (btnValueBasic === "Selecionar") {
                setBtnValueBasic("Cancelar plano")
                setBtnValueIntermediary('Selecionar');
                setBtnValuePlus('Selecionar');
            }
        }

        if (number == 2) {
            if (btnValueIntermediary === "Selecionar") {
                setBtnValueIntermediary("Cancelar plano")
                setBtnValueBasic('Selecionar');
                setBtnValuePlus('Selecionar');
            }
        }

        if (number == 3) {
            if (btnValuePlus === "Selecionar") {
                setBtnValuePlus("Cancelar plano")
                setBtnValueBasic('Selecionar');
                setBtnValueIntermediary('Selecionar');
            }
        }

    }

    const handleBtnValueYearly = (number) => {

        if (number == 1) {
            if (btnValueBasicYearly === "Selecionar") {
                setBtnValueBasicYearly("Cancelar plano")
                setBtnValueIntermediaryYearly('Selecionar');
                setBtnValuePlusYearly('Selecionar');
            }
        }

        if (number == 2) {
            if (btnValueIntermediaryYearly === "Selecionar") {
                setBtnValueIntermediaryYearly("Cancelar plano")
                setBtnValueBasicYearly('Selecionar');
                setBtnValuePlusYearly('Selecionar');
            }
        }

        if (number == 3) {
            if (btnValuePlusYearly === "Selecionar") {
                setBtnValuePlusYearly("Cancelar plano")
                setBtnValueBasicYearly('Selecionar');
                setBtnValueIntermediaryYearly('Selecionar');
            }
        }

    }




    return (

        <ThowColumLayout
            page={"Partners"}
            colum1={
                <Sidebar page="Plans" />
            }

            colum2={
                <>
                    <HeaderDashboard btnBack={false} name="Planos" />

                    <div className="containerBox" style={container}>

                        <div className="containerBox" style={{ ...container, marginBottom: "10px" }}>
                            <div style={{
                                width: "270px",
                                display: "flex",
                                justifyContent: 'space-between',
                                border: "1px solid #D7D7D7",
                                borderRadius: "100px",
                                padding: "5px",
                                backgroundColor: "#fff"
                            }}>
                                <Button height="35px" background="var(--color-primary)"
                                    borderRadius="60px" marginTop="0" name="Semestral" selectBtn={btnActive}
                                    onClick={() => setBtnActive("Semestral")} border="none" width="130px" />

                                <Button height="35px" background="var(--color-primary)"
                                    borderRadius="60px" marginTop="0" name="Anual" selectBtn={btnActive}
                                    onClick={() => setBtnActive("Anual")} border="none" width="130px" />

                            </div>
                        </div>

                        {
                            btnActive == "Semestral" ? (
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <CardPlans onClick={() => handleBtnValue(1)} btnValue={btnValueBasic} setBtnValue={setBtnValueBasic} type={"Básico"} price={"R$ 7.00"} details={"/ mês"} listBenefits={listBenefits} />
                                    <CardPlans onClick={() => handleBtnValue(2)} btnValue={btnValueIntermediary} setBtnValue={setBtnValueIntermediary} type={"Intermediário"} price={"R$ 14.00"} details={"/ mês"} listBenefits={listBenefits} />
                                    <CardPlans onClick={() => handleBtnValue(3)} btnValue={btnValuePlus} setBtnValue={setBtnValuePlus} type={"Avançado"} price={"R$ 21.00"} details={"/ mês"} listBenefits={listBenefits} />
                                </div>

                            )
                                :
                                (
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <CardPlans onClick={() => handleBtnValueYearly(1)} btnValue={btnValueBasicYearly} setBtnValue={setBtnValueBasicYearly} type={"Básico"} price={"R$ 7.00"} details={"/ anual"} listBenefits={listBenefits} />
                                        <CardPlans onClick={() => handleBtnValueYearly(2)} btnValue={btnValueIntermediaryYearly} setBtnValue={setBtnValueIntermediaryYearly} type={"Intermediário"} price={"R$ 14.00"} details={"/ anual"} listBenefits={listBenefits} />
                                        <CardPlans onClick={() => handleBtnValueYearly(3)} btnValue={btnValuePlusYearly} setBtnValue={setBtnValuePlusYearly} type={"Avançado"} price={"R$ 21.00"} details={"/ anual"} listBenefits={listBenefits} />
                                    </div>
                                )
                        }

                    </div>
                </>
            }


        >

        </ThowColumLayout>

    )

}

export default Plans