import axios from "axios";

//debugger
const api = axios.create({
  // baseURL: "http://localhost:8080",
  // baseURL: "http://146.190.186.215",
  baseURL: "https://api.guidz.com.br",
  // baseURL: "https://api-guidz-dot-absolute-router-392014.de.r.appspot.com",
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("@QuboClient:");
    const tokenAdmin = localStorage.getItem("@QuboAdmin:");

    const tokenEmployee = localStorage.getItem("@QuboEmployee:");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } else if (tokenAdmin) {
      config.headers["Authorization"] = `Bearer ${tokenAdmin}`;
    } else if (tokenEmployee) {
      config.headers["Authorization"] = `Bearer ${tokenEmployee}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
