import { useState } from "react";
import arrowdown from "../../../images/png/arrowdown.png";
import arrowUp from "../../../images/png/arrowUp.png";
import { useEffect } from "react";
import api from "../../../services/api";
import CardContentTrailEvolution from "./CardContentTrailEvolution";
import { DashboardClientContext } from "../../../context/DashboardClient";
import { useContext } from "react";

const CardHeaderTrailEvolution = ({ content, state }) => {
  const [showContent, setShowContent] = useState(false);
  const { update, setUpdate } = useContext(DashboardClientContext);

  const cardQuestionsHeader = {
    display: "flex",
    gap: "25px",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #D7D7D7",
    paddingBottom: "32px",
  };

  useEffect(() => {
    setShowContent(false);
  }, [state]);

  return (
    <>
      <div style={cardQuestionsHeader}>
        <div
          style={{
            display: "flex",
            gap: "25px",
            alignItems: "center",
          }}
        >
          <img
            style={{ cursor: "pointer" }}
            src={showContent ? arrowUp : arrowdown}
            onClick={() => setShowContent(!showContent)}
          />

          <p>{content.column}</p>
        </div>

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "18px",
            }}
          >
            <p>Status</p>
            <p>{`${content.percent}%`}</p>
          </div>
          <div
            style={{
              width: "216px",
              height: "7px",
              background: "#D8D8D8",
              border: "1px solid transparent",
              borderRadius: "50px",
            }}
          >
            <div
              style={{
                width: `${content.percent}%`,
                backgroundColor: "var(--color-primary)",
                height: "100%",
                border: "1px solid transparent",
                borderRadius: "50px",
              }}
            ></div>
          </div>
        </div>
      </div>

      {showContent &&
        content.tasks.map((item, i, list) => {
          return (
            <CardContentTrailEvolution
              concluded={item.concluded}
              type={item.taskResponse.type}
              task={item.taskResponse.task}
              link={item.taskResponse.content}
              partner={item.taskResponse.partner}
              length={list.length}
              idTask={item.taskResponse.id}
              id={item.taskResponse.question_id}
            />
          );
        })}
    </>
  );
};

export default CardHeaderTrailEvolution;
