import { Routes, Route, Navigate } from "react-router-dom";
import Landing from "../pages/Landing/Initial/index";
import Register from "../pages/Register/Initial";
import ForgotPassword from "../pages/ResetPassword/Initial"
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import SendMail from "../pages/ResetPassword/SendMail";
import Dashboard from "../pages/Dashboard/Initial";
import TrailOfEvolution from "../pages/Dashboard/TrailOfEvolution";
import Partners from "../pages/Dashboard/Partners";
import Profile from "../pages/Dashboard/Profile";
import ManageAccesses from "../pages/Dashboard/ManageAccesses";
import Preview from "../pages/Dashboard/Preview";
import PrivateRoute from "../pages/PrivateRoute";
import Plans from "../pages/Dashboard/Plans";


export const RoutesUrl = () => {
  <PrivateRoute>
    <Landing />
  </PrivateRoute>

  return (
    <Routes>
      <Route path="*" element={<Landing />} />
      <Route path="/" element={<Landing />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot/password" element={<ForgotPassword />} />
      <Route path="/send/mail" element={<SendMail />} />
      <Route path="/reset/password" element={<ResetPassword />} />

      <Route path="/dashboard" element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      } />
      
      <Route path="/dashboard/preview" element={
        <PrivateRoute>
          <Preview />
        </PrivateRoute>
      } />
      <Route path="/dashboard/trail" element={
        <PrivateRoute>
          <TrailOfEvolution />
        </PrivateRoute>
      } />
      <Route path="/dashboard/partners" element={
        <PrivateRoute>
          <Partners />
        </PrivateRoute>
      } />
      {/* <Route path="/dashboard/plans" element={
        <PrivateRoute>
          <Plans />
        </PrivateRoute>
      } /> */}
      <Route path="/dashboard/profile" element={
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      } />
      <Route path="/dashboard/manage" element={
        <PrivateRoute>
          <ManageAccesses />
        </PrivateRoute>
      } />

    </Routes>
  );
};
