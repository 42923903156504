// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hoverButton:hover {
  opacity: 0.5;
  transition: 0.3s;
}
.hoverButton {
  transition: 0.3s;
}

.sidebarOptions {
  color: var(--grey-5);
}
.sidebarOptions:hover {
  background-color: var(--hover);
  color: var(--color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/styles/hover.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB;AACA;EACE,8BAA8B;EAC9B,2BAA2B;AAC7B","sourcesContent":[".hoverButton:hover {\n  opacity: 0.5;\n  transition: 0.3s;\n}\n.hoverButton {\n  transition: 0.3s;\n}\n\n.sidebarOptions {\n  color: var(--grey-5);\n}\n.sidebarOptions:hover {\n  background-color: var(--hover);\n  color: var(--color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
