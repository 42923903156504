import firebase from "firebase/compat/app";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCOm6iakUDM3Gl_r26CTPLdjtFNyQw6_Xw",
  authDomain: "qubo-project.firebaseapp.com",
  databaseURL: "https://qubo-project-default-rtdb.firebaseio.com",
  projectId: "qubo-project",
  storageBucket: "qubo-project.appspot.com",
  messagingSenderId: "234786174396",
  appId: "1:234786174396:web:dedc7ca34c3a1baa1c27b9",
  measurementId: "G-NMZ291V461",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export default app;
