import React, { useState, useEffect, useContext } from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../components/Sidebar";
import HeaderDashboard from "../components/HeaderDashboard";
import Button from "../components/Button";
import { DashboardClientContext } from "../../../context/DashboardClient";
import CardHeaderTrailEvolution from "../../Dashboard/components/CardHeaderTrailEvolution";
import Loading from "../components/Loading";

const TrailOfEvolution = () => {
  const { listTrailEvolution } = useContext(DashboardClientContext);

  /* BUTTONS SECTION */
  const [btnActive, setBtnActive] = useState("");
  const [btnSelect, setBtnSelect] = useState([]);

  /* CONTENT CARD */
  const [showContent, setShowContent] = useState(false);

  const getByMacro = (name) => {};

  useEffect(() => {
    const tempMacro = sessionStorage.getItem("GUIDZ_MACRO:");
    if (listTrailEvolution.length > 0) {
      if (tempMacro) {
        const filter = listTrailEvolution.filter(
          (item) => item.macro === tempMacro
        );

        setBtnActive(filter[0].macro);
        setBtnSelect(filter[0]);
      } else {
        setBtnActive(listTrailEvolution[0].macro);
        setBtnSelect(listTrailEvolution[0]);
      }
    }
  }, [listTrailEvolution]);

  useEffect(() => {
    setShowContent(!showContent);
  }, [btnActive]);

  /* STYLES */
  const container = {
    maxWidth: "100%",
    paddingTop: "2.6875rem",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginBottom: "20px",
  };

  const cardQuestions = {
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    height: "auto",
    padding: "33px 22px 10px",
    marginBottom: "20px",
  };

  return (
    <ThowColumLayout
      page={"TrailOfEvolution"}
      colum1={<Sidebar page="TrailOfEvolution" />}
      colum2={
        <>
          <HeaderDashboard name="Jornada" btnBack={false} />

          <div className="containerBox" style={container}>
            {listTrailEvolution.length > 0 ? (
              <>
                <div
                  style={{
                    width: "100%",
                    overflowX: "auto",
                  }}
                >
                  <div
                    style={{
                      minWidth: "1000px",
                      display: "flex",
                      border: "1px solid #D7D7D7",
                      borderRadius: "100px",
                      padding: "5px",
                      gap: "10px",
                      marginBottom: "16px",
                      backgroundColor: "#fff",
                    }}
                  >
                    {listTrailEvolution?.map((item, i) => {
                      return (
                        <Button
                          padding={"0 30px"}
                          className="btnStateTrail"
                          height="35px"
                          background="var(--color-primary)"
                          borderRadius="60px"
                          marginTop="0"
                          name={item.macro}
                          selectBtn={btnActive}
                          onClick={() => {
                            sessionStorage.setItem("GUIDZ_MACRO:", item.macro);
                            setBtnActive(item.macro);
                            setBtnSelect(item);
                            getByMacro(item);
                          }}
                          border="none"
                        />
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {btnSelect?.content?.map((item) => {
                      return (
                        <div style={cardQuestions}>
                          <CardHeaderTrailEvolution
                            state={showContent}
                            stateContent={false}
                            content={item}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <Loading />
            )}
          </div>
        </>
      }
    ></ThowColumLayout>
  );
};

export default TrailOfEvolution;
