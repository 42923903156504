import Button from "./Button";

const CardPartners = ({
  image,
  company,
  description,
  discount,
  openModal,
  type,
}) => {
  return (
    <>
      <div
        style={{
          width: type == "opportunities" ? "100%" : "48%",
          height: "245px",
          border: "1px solid #D7D7D7",
          borderRadius: "7px",
          padding: "0 16px 20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            style={{
              padding: "18px 0 0",
            }}
          >
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "27px",
                objectFit: "contain",
              }}
              src={image}
            />
          </div>

          <div>
            <h4
              className={
                type === "opportunities"
                  ? "textCompanyTitleCardDetails"
                  : "textCompanyTitleCard"
              }
            >
              {type === "opportunities" ? company : company}
            </h4>
            <p className="textCompanyDescriptionCard">{description}</p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "21px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            {discount ? (
              <span
                className="textCompanyDiscount"
                style={{
                  backgroundColor: "#EFF4FF",
                  border: "1px solid trasnparent",
                  borderRadius: "60px",
                  padding: "6px 14px",
                }}
              >
                {discount}
              </span>
            ) : (
              <></>
            )}
          </div>
          <Button
            height="30px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Saiba mais"
            selectBtn={"Saiba mais"}
            onClick={openModal}
            border="none"
            width="100%"
          />
        </div>
      </div>
    </>
  );
};

export default CardPartners;
