import Button from "./Button";
import pdfIcon from "../../../images/svg/pdfIcon.svg";
import fileDownload from "js-file-download";
import { useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const CardNegotiations = ({ image, status, onModal, partner, urls }) => {
  const handleDownload = async () => {
    // Decodificar a URL
    const decodedUrl = decodeURIComponent(urls[0]);

    // Extrair o caminho após o último '/'
    const pathIndex = decodedUrl.lastIndexOf("/") + 1;
    const filePath = decodedUrl.substring(pathIndex);

    // Decodificar os caracteres especiais no caminho do arquivo
    const decodedFilePath = decodeURIComponent(filePath);

    // Obter o nome do arquivo
    const fileName = decodedFilePath
      .substring(decodedFilePath.lastIndexOf("/") + 1)
      .split("?")[0];

    // Obter a referência ao Storage do Firebase
    const storage = getStorage();

    // Criar uma referência ao seu arquivo no Firebase Storage
    const fileRef = ref(storage, `/proposal/${fileName}`);

    getDownloadURL(fileRef)
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
          const blob = xhr.response;
        };
        xhr.open("GET", url);
        xhr.send();

        // Or inserted into an <img> element
        const img = document.getElementById("myimg");
        img.setAttribute("src", url);
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  return (
    <div
      style={{
        width: "319px",
        height: "248px",
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        padding: "20px",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid #D7D7D7",
          paddingBottom: "17px",
          marginBottom: "17px",
        }}
      >
        <img
          style={{ maxWidth: "40%", maxHeight: "38px", objectFit: "contain" }}
          src={image}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "18px",
          alignItems: "center",
          height: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <p className="textProposalNegotiations">Proposta comercial</p>
          <a href={urls[0]} download="proposta.pdf" target="_blank">
            <img style={{ cursor: "pointer" }} src={pdfIcon} />
          </a>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <p className="textProposalNegotiations">Status</p>
          <span
            className="textStatusContract"
            style={{
              backgroundColor:
                status == "In negociation" ? "#FFF7AA" : "#D4FFF4",
              color: status == "In negociation" ? "#C6B200" : "#00B386",
              padding: "5px 24px",
              border: "1px solid transparent",
              borderRadius: "60px",
            }}
          >
            {status == "In negociation"
              ? "Assinar contrato"
              : "Contrato assinado"}
          </span>
        </div>
        <Button
          height="33px"
          background="var(--color-primary)"
          onClick={onModal}
          borderRadius="60px"
          marginTop="0"
          name={
            status == "In negociation"
              ? "Assinar contrato"
              : "Visualizar contrato"
          }
          selectBtn={
            status == "In negociation"
              ? "Assinar contrato"
              : "Visualizar contrato"
          }
          border="none"
          width="100%"
        />
      </div>
    </div>
  );
};

export default CardNegotiations;
