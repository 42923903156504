

const PercentColumn = ({ name, percent }) => {

    const container = {
        display: "flex",
        height: "100%",
        minWidth: "82px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end"
    }

    const boxContent = {
        minWidth: "50px",
        height: `${percent}px`,
        marginBottom: "10px",
        backgroundColor: "var(--color-primary)",
        borderRadius: "4px"
    }

    return (
        <div style={container}>
            <div style={boxContent}>
            </div>
            <p className='textContentPercentCard'>{name}</p>
        </div>
    )
}

export default PercentColumn