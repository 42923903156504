import React, { useState } from 'react';

const InputRadio = ({ options, name, onChange}) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionSelected = (option) => {
        setSelectedOption(option);
        onChange(option.charAt(1).toUpperCase() + option.slice(2).trim())
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        marginBottom: "27px"
    };

    const radioContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        cursor: "pointer"
    };

    const radioStyle = {
        marginRight: '8px',
        outline: "none"
    };

    const labelStyle = {
        fontFamily: 'Arial, sans-serif',
        fontSize: '14px',
    };

    return (
        <div>

            <div style={containerStyle}>
                <p className='textPrimaryLabelInput'>{name}</p>
                {options.map((option, index) => (
                    <div key={index} style={radioContainerStyle}>
                        <input
                            id={option}
                            name={option}
                            type="radio"
                            value={option}
                            checked={option === selectedOption}
                            onChange={() => handleOptionSelected(option)}
                            style={radioStyle}
                        />
                        <label htmlFor={option} style={labelStyle}>{option.charAt(1).toUpperCase() + option.slice(2)}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InputRadio;
