import { useEffect, useState } from "react";
import api from "../../../services/api";
import PercentColumn from "./PercentColumn";

const PercentageCharts = ({
  title,
  type,
  journeyPercentage,
  setJourneyPercentage,
  macroPercentage,
  setMacroPercentage,
}) => {
  const getJourney = async () => {
    try {
      const response = await api.get("/journey/percentage/preview");
      setJourneyPercentage(response.data);
    } catch (error) {}
  };

  const getMacro = async () => {
    try {
      const response = await api.get("/macro/percentage/preview");
      console.log(response.data);
      setMacroPercentage(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (type == "macro") {
      getMacro();
    } else {
      getJourney();
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        border: "1px solid #d7d7d7",
        borderRadius: "10px",
        height: "390px",
        padding: "20px 18px",
        backgroundColor: "#fff",
      }}
    >
      <div style={{ textAlign: "start" }}>
        <h3 className="textTitleCardPercent">{title}</h3>
      </div>

      <div
        style={{
          display: "flex",
          paddingTop: "35px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50px",
            gap: "25px",
            alignItems: "center",
          }}
        >
          <p className="textContentPercentCard">100%</p>
          <p className="textContentPercentCard">80%</p>
          <p className="textContentPercentCard">60%</p>
          <p className="textContentPercentCard">40%</p>
          <p className="textContentPercentCard">20%</p>
          <p className="textContentPercentCard">0</p>
        </div>

        <div
          style={{
            height: "280px",
            display: "flex",
            alignItems: "flex-end",
            paddingLeft: "50px",
            gap: "83px",
            width: "100%",
            overflow: "hidden",
            overflowX: "auto",
          }}
        >
          {type == "macro"
            ? macroPercentage?.map((item, index) => {
                return (
                  <PercentColumn
                    name={`${index + 1}. ${item.macro}`}
                    percent={
                      item.percentage == 0 ? 1 * 2.49 : item.percentage * 2.49
                    }
                  />
                );
              })
            : journeyPercentage?.map((item, index) => {
                return (
                  <PercentColumn
                    name={`${index + 1}. ${item.journey}`}
                    percent={
                      item.percentage == 0 ? 1 * 2.49 : item.percentage * 2.49
                    }
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default PercentageCharts;
