import React, { useState } from "react";
import closeEyeIcon from "../../../images/svg/closeEyeIcon.svg";
import openEyeIcon from "../../../images/svg/openEyeIcon.svg";
import InputMask from "react-input-mask";
const InputSelect = ({
  name,
  type,
  placeholder,
  onChange,
  width,
  error,
  height,
  marginLeft,
  color,
  money,
  value,
  rightEye,
  topEye,
  mask,
}) => {
  const [stateEye, setStateEye] = useState(false);

  const containerInput = {
    display: "flex",
    flexDirection: "column",
    marginLeft: marginLeft,
    width: width,
  };

  const styleLabel = {
    marginBottom: "14.43px",
  };

  const containerInputMail = {
    width: width != undefined ? width : "418px",

    display: "flex",
    flexDirection: "column",
    position: "relative",
  };

  const containerInputPassowrd = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: width != undefined ? width : "418px",
  };

  const image = {
    position: "absolute",
    right: 0,
    top: topEye != undefined ? topEye : "50px",
    right: rightEye != undefined ? rightEye : "10px",
    cursor: "pointer",
  };

  return (
    <div style={containerInput}>
      {
        <div style={containerInputMail}>
          <label className="textPrimaryLabelInput" style={styleLabel}>
            {name}
          </label>

          <select
            name="select"
            onChange={onChange}
            defaultValue={value}
            style={{
              outline: "none",
              width: "100%",
              border: "1px solid #d7d7d7",
              padding: "12px 10px",
              marginBottom: "1.6875rem",
              borderRadius: "8px",
            }}
          >
            <option value="default">Todos</option>
            <option value="Indústria">Indústria</option>
            <option value="Distribuidora / Revenda / Representação">
              Distribuidora / Revenda / Representação
            </option>
            <option value="Saúde">Saúde</option>
            <option value="Seguros">Seguros</option>
            <option value="Serviços com capital financeiro intensivo">
              Serviços com capital financeiro intensivo
            </option>
            <option value="Serviços com capital humano intensivo">
              Serviços com capital humano intensivo
            </option>
            <option value="Serviços financeiros">Serviços financeiros</option>
            <option value="Software e tecnologia">Software e tecnologia</option>
            <option value="Outros">Outros</option>
          </select>
        </div>
      }
    </div>
  );
};

export default InputSelect;
